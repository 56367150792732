import React, { Component } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import styled from 'styled-components'
import { BlobServiceClient } from '@azure/storage-blob'
import intl from 'react-intl-universal'
import { Form, Radio, Select, Input, Upload, Button, Image, Modal, List, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ccmsService from '../services/ccmsService'
import { checkFile, getFormatFile, getFormatInformRequset, getFormatExtraInformRequset } from '../common/utils'
import { FILE_CHECK_RESULT_TYPE_ERROR, FILE_CHECK_RESULT_SIZE_ERROR, INFORMER_SECURITY_TYPE_ENUM } from '../common/const'
import { ArrowDownIcon, UploadButtonIcon, UploadFolderIcon, DeleteIcon, FileTypeIcon, CheckIcon, RefreshIcon } from '../common/icon'
import UploadSupportFileListExpand from '../components/UploadSupportFileListExpand'

const { Option } = Select

const PcFormContainer = styled.div`
    width: 1024px;
    background-color: white;

    .ant-select-selector {
        width: 300px !important;
    }

    .ant-input {
        width: 300px !important;

        &.kaptcha-code-input {
            width: 250px !important;
        }
    }

    textarea.ant-input {
        &.inform-content-input-textarea {
            width: 610px !important;
            height: 120px !important;
        }
    }

    .ant-form-item {
        text-align: left;
        margin-bottom: 10px;
    }

    .ant-btn {
        &.submit-button {
            width: 190px;
            height: 44px;
        }
    }
`

const FormSectionContainer = styled.div`
    display: flex;
`

const FormTabsContainer = styled.div`
    flex-basis: 210px;
    background-color: white;
    padding-top: 30px;
`

const FormTabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TabItem = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const TabName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 15px;
    color: #9e9e9e;
    line-height: 1;
    text-align: center;

    &.active {
        font-size: 18px;
        font-weight: bold;
        color: #00182e;
    }
`

const TabBorder = styled.div`
    width: 4px;
    height: 60px;
    background-color: #1890ff;
    display: flex;
    border-radius: 2px;
`

const FromContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
`

const DisclaimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`

const DisclaimerLabel = styled.div`
    color: #bd0017;
    text-align: left;
    font-weight: bold;
`

const ImformMessage = styled.p`
    padding: 8px 0 20px;
    text-align: left;
    // border-bottom: 1px dashed rgba(59, 59, 59, 0.2);
    font-weight: bold;
`

const BottomImformMessage = styled.p`
    padding: 20px 0 20px;
    text-align: left;
    border-top: 1px dashed rgba(59, 59, 59, 0.2);
    font-weight: bold;
    margin-top: 10px;
`

const TabContentContainer = styled.div`
    min-height: calc(100vh - 140px);
`

const CreateInformContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ExtraInformContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FromItemTitleContainer = styled.div`
    display: flex;
    align-items: flex-end;
`

const FromItemTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    text-align: left;
`

const FromItemSubTitle = styled.p`
    text-align: left;
`

const RequiredHint = styled.p`
    text-align: left;
    font-size: 12px;
    opacity: 0.8;
    margin-left: 10px;
`

const RadioButtonContainer = styled.div`
    display: flex;
`

const RadioButtonItem = styled.div`
    width: 50%;
    display: flex;
    margin-right: 50px;
`

const WidthBox = styled.div`
    width: 10px;
    height: 100%;
`

const HeightBox = styled.div`
    height: 10px;
`

const FromItemRowContainer = styled.div`
    display: flex;
`

const FormInformerContainer = styled.div`
    padding: 20px 0 0;
`

const FormInformInfoContainer = styled.div`
    padding: 20px 0 10px;
`

const FormInformedContainer = styled.div`
    padding: 20px 0 0;
`

const FormVerificationContainer = styled.div`
    padding: 20px 0 0;
`

const FormIssueTypeContainer = styled.div`
    width: 300px;
`

const InformEventHint = styled.p`
    text-align: left;
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 6px;
`

const FormInfoUploadContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    // width: 300px;
    min-width: 300px;
    background-color: #f9fcff;
    border: 1px solid #e6eff8;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.3s, height 0s;

    :hover {
        border: 1px solid #1890ff;
    }
`

const UploadSectionLeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const UploadSectionRightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`

const UploadHint = styled.div`
    color: #9e9e9e;
    margin-left: 10px;
`

const UploadFileListContainer = styled.div`
    width: 601px;
`

const FileListItemContainer = styled.div`
    width: 300px;
    display: flex;
    justify-content: space-between;
    color: #ffc836;
    background-color: #fffdf9;
    border: 1px solid #feedcd;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 14px;

    &.error {
        color: #bd0017;
        background-color: #fff9f9;
        border: 1px solid #f3aaaa;
    }
`

const FileListItemLeftContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-start;
`

const FileListItemRightContainer = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FileListItemName = styled.div`
    font-size: 12px;
    text-align: left;
    margin-left: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
`

const FileListItemErrorMessage = styled.div`
    color: #bd0017;
    font-size: 12px;
    text-align: left;
`

const VerificationContainer = styled.div`
    display: flex;
`

const VerificationInputContainer = styled.div`
    width: 260px;
`

const VerificationImageContainer = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const FormSubmitContainer = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
`

const FormInformNumberContainer = styled.div`
    padding: 20px 0 0;
`

const InformNumberInputContainer = styled.div`
    display: flex;
    align-items: center;
`

const FormInformExtraMoreContainer = styled.div`
`

const FormInformExtraInfoContainer = styled.div`
    padding: 20px 0 10px;
`

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 40px 0;
    overflow: auto;
`

const ErrorMessageLabel = styled.div`
    font-size: 16px;
    text-align: center;
`

class PcForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            containerClient: Object,
            isGetSASDone: false,
            isInCreateInform: true,
            informerSecurityType: INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value,
            isUploadSupportFileListExpand: false,
            isUploadSupportFileListExpandForExtra: false,
            fileList: [],
            fileListForExtra: [],
            isInformerTypeCommentVisable: false,
            isInformTypeCommentVisable: false,
            isInformedTypeCommentVisable: false,
            kaptchaImageString: '',
            informNumber: '',
            hasCheckedInformNumber: false,
            kaptchaCodeValidateStatus: 'success',
            kaptchaCodeValidateHelp: '',
            informNumberValidateStatus: 'success',
            kaptchaCodeForExtraValidateStatus: 'success',
            kaptchaCodeForExtraValidateHelp: '',
            isErrorMessageModalVisible: false,
            errorMessage: '',
            isGoBack: true
        }
    }

    componentDidMount() {
        const previousPage = sessionStorage.getItem('previousPage')
        if (previousPage === 'notice' || previousPage === 'success') {
            document.body.scrollIntoView()
            this.getSAS()
            this.getKaptchaCode({ kaptchaId: undefined })
        } else {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: '/home' })
            })
        }
    }

    async getSAS() {
        var response = await ccmsService.getSAS()
        if (response.code === 200) {
            let accoutName = response.data.accoutName
            let endPorintStuffix = response.data.endPorintStuffix
            let token = response.data.token
            let containerName = response.data.containerName

            const blobSasUrl = `https://${accoutName}.blob.${endPorintStuffix}/?${token}`
            const blobServiceClient = new BlobServiceClient(blobSasUrl)

            this.setState({
                containerClient: blobServiceClient.getContainerClient(containerName),
                isGetSASDone: true,
            })
        }
    }

    getKaptchaCode = async (request) => {
        var response = await ccmsService.getKaptchaCode(request)
        if (response.code === 200) {
            this.setState({
                kaptchaImageString: response.data.imageString,
                kaptchaId: response.data.kaptchaId
            })
        }
    }

    checkIsFileValid = async (file, fileList) => {
        // let duplicateFile = fileList.filter(item => { return item.name === file.name })
        // if (duplicateFile.length > 0) {
        //     this.setState({
        //         errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_DUPLICATE_ERROR'),
        //         isErrorMessageModalVisible: true
        //     })
        //     return false
        // }
        if (fileList.length >= 9) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_COUNT_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        }

        let checkResult = await checkFile(file)

        if (checkResult === FILE_CHECK_RESULT_TYPE_ERROR) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_TYPE_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        } else if (checkResult === FILE_CHECK_RESULT_SIZE_ERROR) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_SIZE_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        }

        return true
    }

    onChangeTabs = (key) => {
        if (key === 'createInform') {
            this.setState({ isInCreateInform: true })
        } else {
            this.setState({ isInCreateInform: false })
        }
    }

    onChangeInformerSecurityType = e => {
        this.setState({ informerSecurityType: e.target.value })
    }

    onChangeInformerType = (value) => {
        if (value === 'other') {
            this.setState({ isInformerTypeCommentVisable: true })
        } else {
            this.setState({ isInformerTypeCommentVisable: false })
        }
    }

    onChangeInformType = (value) => {
        if (value === 'complianceInformOther') {
            this.setState({ isInformTypeCommentVisable: true })
        } else {
            this.setState({ isInformTypeCommentVisable: false })
        }
    }

    onChangeInformedType = (value) => {
        if (value === 'other') {
            this.setState({ isInformedTypeCommentVisable: true })
        } else {
            this.setState({ isInformedTypeCommentVisable: false })
        }
    }

    onCLickUploadSupportFileListHeader = () => {
        this.setState({ isUploadSupportFileListExpand: !this.state.isUploadSupportFileListExpand })
    }

    onClickFileListDeleteIcon = (generatedFileName) => {
        this.state.fileList.forEach((item, index) => {
            if (item.generatedFileName === generatedFileName) {
                this.state.fileList.splice(index, 1)
                this.setState(state => ({ fileList: [...state.fileList] }))
            }
        })
    }

    handleCustomRequest = obj => {
        this.uploadFile(obj.file, 'createInform')
    }

    uploadFile = async (file, type) => {
        let isCreateInform = type === 'createInform'
        let currnetFileList = isCreateInform ? this.state.fileList : this.state.fileListForExtra

        if (!await this.checkIsFileValid(file, currnetFileList)) return false

        let formatFile = getFormatFile(file)
        formatFile.status = 'loading'
        if (isCreateInform) {
            this.setState(state => ({ fileList: [...state.fileList, formatFile] }))
        } else {
            this.setState(state => ({ fileListForExtra: [...state.fileListForExtra, formatFile] }))
        }

        try {
            const promises = []
            const blockBlobClient = this.state.containerClient.getBlockBlobClient(formatFile.generatedFileName)
            promises.push(blockBlobClient.uploadBrowserData(formatFile))
            await Promise.all(promises).then(() => {
                formatFile.status = 'done'
                if (isCreateInform) {
                    this.setState(state => ({ fileList: [...state.fileList] }))
                } else {
                    this.setState(state => ({ fileListForExtra: [...state.fileListForExtra] }))
                }
            })
        }
        catch (error) {
            formatFile.status = 'error'
            if (isCreateInform) {
                this.setState(state => ({ fileList: [...state.fileList] }))
            } else {
                this.setState(state => ({ fileListForExtra: [...state.fileListForExtra] }))
            }
        }
    }

    onChangeKaptchaCode = () => {
        this.setState({
            kaptchaCodeValidateStatus: 'success',
            kaptchaCodeValidateHelp: ''
        })
    }

    onClickRefreshKaptchaCode = () => {
        this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
    }

    onFinishCreateInform = values => {
        if (this.checkIsValuesValid(values)) {
            this.submitInform(values)
        }
    }

    onFinishFailedCreateInform = obj => {
        let kaptchaCode = obj.values.kaptchaCode
        if (!kaptchaCode || kaptchaCode.trim() === '') {
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        } else {
            this.setState({
                kaptchaCodeValidateStatus: 'success',
                kaptchaCodeValidateHelp: ''
            })
        }
    }

    checkIsValuesValid = values => {
        let isValid = true
        if (values.kaptchaCode && values.kaptchaCode.trim() !== '') {
            this.setState({
                kaptchaCodeValidateStatus: 'success',
                kaptchaCodeValidateHelp: ''
            })
        } else {
            isValid = false
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        }

        return isValid
    }

    async submitInform(values) {
        let request = getFormatInformRequset(values, this.state.fileList, this.state.kaptchaId)
        var response = await ccmsService.submitInform(request)
        if (response.code === 200) {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: `/success/${response.data.informNumber}` })
            })
        } else if (response.code === 1002) {
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 1003) {
            this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 4001) {
          this.setState({
            errorMessage: intl.get('ERROR_MESSAGE_INPUT_VALID'),
            isErrorMessageModalVisible: true
          })
        }
    }

    onChangeInformNumber = e => {
        this.setState({ informNumber: e.target.value })
    }

    onClickCheckInformNumberIcon = async () => {
        let informNumber = this.state.informNumber.trim()
        var response = await ccmsService.checkInformNumber(informNumber)
        if (response.code === 200) {
            this.setState({
                hasCheckedInformNumber: true,
                informNumberValidateStatus: 'success'
            })
        } else if (response.code === 1001) {
            this.setState({
                hasCheckedInformNumber: false,
                informNumberValidateStatus: 'error'
            })
        }
    }

    onClickFileListForExtraDeleteIcon = (generatedFileName) => {
        this.state.fileListForExtra.forEach((item, index) => {
            if (item.generatedFileName === generatedFileName) {
                this.state.fileListForExtra.splice(index, 1)
                this.setState(state => ({
                    fileListForExtra: [...state.fileListForExtra],
                }))
            }
        })
    }

    handleCustomRequestForExtra = obj => {
        this.uploadFile(obj.file, 'extraInform')
    }

    onCLickUploadSupportFileListHeaderForExtra = () => {
        this.setState({ isUploadSupportFileListExpandForExtra: !this.state.isUploadSupportFileListExpandForExtra })
    }

    onChangeKaptchaCodeForExtra = () => {
        this.setState({
            kaptchaCodeForExtraValidateStatus: 'success',
            kaptchaCodeForExtraValidateHelp: '',
        })
    }

    onFinishExtraInform = values => {
        if (this.checkIsValuesValidForExtra(values)) {
            this.submitInformForExtra(values)
        }
    }

    onFinishFailedExtraInform = obj => {
        let kaptchaCodeForExtra = obj.values.kaptchaCodeForExtra
        if (!kaptchaCodeForExtra || kaptchaCodeForExtra.trim() === '') {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        } else {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'success',
                kaptchaCodeForExtraValidateHelp: ''
            })
        }
    }

    checkIsValuesValidForExtra = values => {
        let isValid = true
        if (values.kaptchaCodeForExtra && values.kaptchaCodeForExtra.trim() !== '') {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'success',
                kaptchaCodeForExtraValidateHelp: ''
            })
        } else {
            isValid = false
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        }

        return isValid
    }

    async submitInformForExtra(values) {
        let request = getFormatExtraInformRequset(values, this.state.fileListForExtra, this.state.kaptchaId)
        var response = await ccmsService.submitInformExtra(request)
        if (response.code === 200) {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: `/success/${response.data.informNumber}` })
            })
        } else if (response.code === 1001) {
            this.setState({ informNumberValidateStatus: 'error' })
        } else if (response.code === 1002) {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 1003) {
            this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        }  else if (response.code === 4001) {
          this.setState({
            errorMessage: intl.get('ERROR_MESSAGE_INPUT_VALID'),
            isErrorMessageModalVisible: true
          })
        }
    }

    closeErrorMessageModal = () => {
        this.setState({
            errorMessage: '',
            isErrorMessageModalVisible: false
        })
    }

    render() {
        return (
            <PcFormContainer className="pc-form-container">
                <Prompt when={this.state.isGoBack} message={intl.get('GO_BACK_HINT')} />
                <FormSectionContainer>
                    <FormTabsContainer>
                        <FormTabs>
                            <TabItem className="cursor-pointer" onClick={this.onChangeTabs.bind(this, 'createInform')}>
                                <TabName className={this.state.isInCreateInform ? "active" : null}>{intl.get('CREATE_INFORM')}</TabName>
                                {this.state.isInCreateInform && (<TabBorder />)}
                            </TabItem>

                            <TabItem className="cursor-pointer" onClick={this.onChangeTabs.bind(this, 'extraInform')}>
                                <TabName className={!this.state.isInCreateInform ? "active" : null}>{intl.get('EXTRA_INFORM')}</TabName>
                                {!this.state.isInCreateInform && (<TabBorder />)}
                            </TabItem>
                        </FormTabs>
                    </FormTabsContainer>
                    <FromContentContainer>
                        {
                            <TabContentContainer>
                                <CreateInformContentContainer style={{ display: (this.state.isInCreateInform ? "block" : "none") }}>
                                    <DisclaimerContainer>
                                        <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL1')}</DisclaimerLabel>
                                        <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL2')}</DisclaimerLabel>
                                    </DisclaimerContainer>
                                    <ImformMessage>{intl.get('INFORM_MESSAGE')}</ImformMessage>
                                    <Divider dashed />
                                    <Form
                                        scrollToFirstError={true}
                                        onFinish={this.onFinishCreateInform}
                                        onFinishFailed={this.onFinishFailedCreateInform}>
                                        <FormInformerContainer>
                                            <FromItemTitleContainer>
                                                <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORMER')}</FromItemTitle>
                                                <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                            </FromItemTitleContainer>

                                            <Form.Item name="informerSecurityType" initialValue={true}>
                                                <Radio.Group onChange={this.onChangeInformerSecurityType} value={this.state.informerSecurityType}>
                                                    <RadioButtonContainer >
                                                        <RadioButtonItem>
                                                            <Radio value={INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value}>{intl.get('INFORM_TYPE_REALNAME')}</Radio>
                                                        </RadioButtonItem>
                                                        <RadioButtonItem>
                                                            <Radio value={INFORMER_SECURITY_TYPE_ENUM.ANONYMOUS.value}>{intl.get('INFORM_TYPE_ANONYMOUSLY')}</Radio>
                                                        </RadioButtonItem>
                                                    </RadioButtonContainer>
                                                </Radio.Group>
                                            </Form.Item>

                                            <FromItemRowContainer>
                                                <Form.Item
                                                    style={{ flexBasis: '300px' }}
                                                    name="informerType"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMER_TYPE_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Select
                                                        placeholder={intl.get('INFORMER_TYPE_PLACEHOLDER')}
                                                        onChange={this.onChangeInformerType}
                                                        allowClear={false}
                                                        suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                        <Option value="employee">{intl.get('INFORMER_TYPE_EMPLOYEE')}</Option>
                                                        <Option value="formerEmployee">{intl.get('INFORMER_TYPE_FORMER_EMPLOYEE')}</Option>
                                                        <Option value="supplier">{intl.get('INFORMER_TYPE_SUPPLIER')}</Option>
                                                        <Option value="partner">{intl.get('INFORMER_TYPE_PARTNER')}</Option>
                                                        <Option value="customer">{intl.get('INFORMER_TYPE_CUSTOMER')}</Option>
                                                        <Option value="other">{intl.get('INFORMER_TYPE_OTHER')}</Option>
                                                    </Select>
                                                </Form.Item>
                                                <WidthBox />

                                                {
                                                    this.state.isInformerTypeCommentVisable && (
                                                        <Form.Item
                                                            style={{ flexBasis: '300px' }}
                                                            name="informerTypeComment"
                                                            rules={[
                                                                { required: true, message: `${intl.get('INFORMER_COMMENT_REQUIRED_MESSAGE')}` },
                                                                { whitespace: true, message: `${intl.get('INFORMER_COMMENT_REQUIRED_MESSAGE')}` }
                                                            ]}>
                                                            <Input maxLength={256} placeholder={intl.get('INFORMER_COMMENT_PLACEHOLDER')} />
                                                        </Form.Item>
                                                    )
                                                }
                                            </FromItemRowContainer>

                                            <FromItemRowContainer>
                                                {
                                                    this.state.informerSecurityType === INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value ?
                                                        <Form.Item
                                                            name="informerNameRequired"
                                                            style={{ flexBasis: '300px' }}
                                                            rules={[
                                                                { required: true, message: `${intl.get('INFORMER_NAME_REQUIRED_MESSAGE')}` },
                                                                { whitespace: true, message: `${intl.get('INFORMER_NAME_REQUIRED_MESSAGE')}` }
                                                            ]}>
                                                            <Input maxLength={32} placeholder={intl.get('INFORMER_NAME_REQUIRED_PLACEHOLDER')} />
                                                        </Form.Item>
                                                        :
                                                        <Form.Item name="informerName" style={{ flexBasis: '300px' }}>
                                                            <Input maxLength={32} placeholder={intl.get('INFORMER_NAME_PLACEHOLDER')} />
                                                        </Form.Item>
                                                }
                                                <WidthBox />
                                                <Form.Item name="informerContactInfo" style={{ flexBasis: '300px' }}>
                                                    <Input maxLength={64} placeholder={intl.get('INFORMER_CONTACT_INFO_PLACEHOLDER')} />
                                                </Form.Item>
                                            </FromItemRowContainer>

                                            <FromItemRowContainer>
                                                <Form.Item name="informerOtherContactInfo" style={{ flexBasis: '300px' }}>
                                                    <Input maxLength={64} placeholder={intl.get('INFORMER_OTHER_CONTACT_INFO_PLACEHOLDER')} />
                                                </Form.Item>
                                            </FromItemRowContainer>
                                        </FormInformerContainer>

                                        <FormInformInfoContainer>
                                            <FromItemTitleContainer>
                                                <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORM_INFO')}</FromItemTitle>
                                                <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                            </FromItemTitleContainer>

                                            <HeightBox />

                                            <FormIssueTypeContainer>
                                                <Form.Item
                                                    style={{ flexBasis: '300px' }}
                                                    name="informRegion"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORM_INFO_INFORM_REGION_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Select
                                                        placeholder={intl.get('INFORM_INFO_INFORM_REGION_INPUT_PLACEHOLDER')}
                                                        allowClear={false}
                                                        suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                            <Option value="mainland">{intl.get('INFORM_REGION_MAINLAND')}</Option>
                                                            <Option value="hk">{intl.get('INFORM_REGION_HK')}</Option>
                                                            <Option value="mo">{intl.get('INFORM_REGION_MO')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </FormIssueTypeContainer>

                                            <HeightBox />

                                            <FormIssueTypeContainer>
                                                <Form.Item
                                                    style={{ flexBasis: '300px' }}
                                                    name="informType"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Select
                                                        placeholder={intl.get('INFORM_INFO_INFORM_TYPE_SELECT_PLACEHOLDER')}
                                                        onChange={this.onChangeInformType}
                                                        allowClear={false}
                                                        virtual={false}
                                                        suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                        <Option value="bribe">{intl.get('INFORM_TYPE_BRIBE')}</Option>
                                                        <Option value="conflictOfInterest">{intl.get('INFORM_TYPE_CONFLICT_OF_INTEREST')}</Option>
                                                        <Option value="counterfeit">{intl.get('INFORM_TYPE_COUNTERFEIT')}</Option>
                                                        <Option value="theft">{intl.get('INFORM_TYPE_THEFT')}</Option>
                                                        <Option value="giftsAndEntertainmentViolation">{intl.get('INFORM_TYPE_GIFT_AND_ENTERTAINMENT_VIOLATION')}</Option>
                                                        <Option value="harassmentOrDiscrimination">{intl.get('INFORM_TYPE_HARASSMENT_OR_DISCRIMINATION')}</Option>
                                                        <Option value="leakCompanyTradeSecrets">{intl.get('INFORM_TYPE_LEAK_COMPANY_TRADE_SECRETS')}</Option>
                                                        <Option value="dataAndNetworkSecurity">{intl.get('INFORM_TYPE_DATA_AND_NETWORK_SECURITY')}</Option>
                                                        <Option value="retaliationAgainstReportingPersonnel">{intl.get('INFORM_TYPE_RETALIATION_AGAINST_REPORTING_PERSONNEL')}</Option>
                                                        <Option value="complianceInformOther">{intl.get('INFORM_TYPE_OTHER')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </FormIssueTypeContainer>

                                            {
                                                this.state.isInformTypeCommentVisable && (
                                                    <Form.Item
                                                        style={{ flexBasis: '300px' }}
                                                        name="informTypeComment"
                                                        rules={[
                                                            { required: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_REQUIRED_MESSAGE')}` },
                                                            { whitespace: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_REQUIRED_MESSAGE')}` }
                                                        ]}>
                                                        <Input maxLength={256} placeholder={intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_PLACEHOLDER')} />
                                                    </Form.Item>
                                                )
                                            }

                                            <FromItemSubTitle>{intl.get('INFORM_INFO_INFORM_EVENT_DESCRIPTION')}</FromItemSubTitle>
                                            <InformEventHint>{intl.get('INFORM_INFO_INFORM_EVENT_HINT')}</InformEventHint>
                                            <Form.Item
                                                name="informContent"
                                                rules={[
                                                    { required: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` },
                                                    { whitespace: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` }
                                                ]}>
                                                <Input.TextArea maxLength={2048} className="inform-content-input-textarea" autoSize={false} />
                                            </Form.Item>

                                            {
                                                this.state.isGetSASDone && (
                                                    <Form.Item name="informFiles">
                                                        <Upload fileList={this.state.fileList} customRequest={this.handleCustomRequest}>
                                                            <FormInfoUploadContainer className="cursor-pointer">
                                                                <UploadSectionLeftContainer>
                                                                    <UploadFolderIcon />
                                                                    <UploadHint>{intl.get('INFORM_INFO_UPLOAD_FILE_LABEL')}</UploadHint>
                                                                </UploadSectionLeftContainer>
                                                                <UploadSectionRightContainer>
                                                                    <UploadButtonIcon />
                                                                </UploadSectionRightContainer>
                                                            </FormInfoUploadContainer>
                                                        </Upload>
                                                    </Form.Item>
                                                )
                                            }
                                            <UploadSupportFileListExpand pc={true} />
                                            <UploadFileListContainer>
                                                {
                                                    this.state.fileList.length > 0 && (
                                                        <List
                                                            grid={{ gutter: 10, column: 2 }}
                                                            size="small"
                                                            dataSource={this.state.fileList}
                                                            renderItem={item =>
                                                                <List.Item>
                                                                    <FileListItemContainer className={item.status !== "error" ? "success" : "error"}>
                                                                        <FileListItemLeftContainer>
                                                                            <FileTypeIcon className={[item.formatType, item.status !== "error" ? "success" : "error"]} />
                                                                            <FileListItemName>{item.name}</FileListItemName>
                                                                        </FileListItemLeftContainer>
                                                                        <FileListItemRightContainer>
                                                                            {
                                                                                item.status === "loading" ?
                                                                                    <LoadingOutlined style={{ fontSize: '20px' }} />
                                                                                    :
                                                                                    <DeleteIcon onClick={this.onClickFileListDeleteIcon.bind(this, item.generatedFileName)} />
                                                                            }
                                                                        </FileListItemRightContainer>
                                                                    </FileListItemContainer>
                                                                    {
                                                                        item.status === "error" && (
                                                                            <FileListItemErrorMessage>{intl.get('FILE_LIST_ITEM_UPLOAD_ERROR_MESSAGE')}</FileListItemErrorMessage>
                                                                        )
                                                                    }
                                                                </List.Item>} />
                                                    )
                                                }
                                            </UploadFileListContainer>
                                        </FormInformInfoContainer>

                                        <FormInformedContainer>
                                            <FromItemTitleContainer>
                                                <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORMED')}</FromItemTitle>
                                                <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                            </FromItemTitleContainer>

                                            <HeightBox />

                                            <FromItemRowContainer>
                                                <Form.Item
                                                    style={{ flexBasis: '300px' }}
                                                    name="informedType"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMED_TYPE_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Select
                                                        placeholder={intl.get('INFORMED_TYPE_SELECT_PLACEHOLDER')}
                                                        onChange={this.onChangeInformedType}
                                                        allowClear={false}
                                                        suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                        <Option value="employee">{intl.get('INFORMED_TYPE_EMPLOYEE')}</Option>
                                                        <Option value="supplier">{intl.get('INFORMED_TYPE_SUPPLIER')}</Option>
                                                        <Option value="franchisee">{intl.get('INFORMED_TYPE_RRANCHISEE')}</Option>
                                                        <Option value="partner">{intl.get('INFORMED_TYPE_PARTNER')}</Option>
                                                        <Option value="other">{intl.get('INFORMED_TYPE_OTHERS')}</Option>
                                                    </Select>
                                                </Form.Item>
                                                <WidthBox />
                                                
                                                {
                                                    this.state.isInformedTypeCommentVisable && (
                                                        <Form.Item
                                                            style={{ flexBasis: '300px' }}
                                                            name="informedTypeComment"
                                                            rules={[
                                                                { required: true, message: `${intl.get('INFORMED_COMMENT_REQUIRED_MESSAGE')}` },
                                                                { whitespace: true, message: `${intl.get('INFORMED_COMMENT_REQUIRED_MESSAGE')}` }
                                                            ]}>
                                                            <Input maxLength={256} placeholder={intl.get('INFORMED_COMMENT_PLACEHOLDER')} />
                                                        </Form.Item>
                                                    )
                                                }
                                            </FromItemRowContainer>

                                            <FromItemRowContainer>

                                            </FromItemRowContainer>

                                            <FromItemRowContainer>
                                                <Form.Item
                                                    style={{ flexBasis: '300px' }}
                                                    name="informedName"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMED_NAME_REQUIRED_MESSAGE')}` },
                                                        { whitespace: true, message: `${intl.get('INFORMED_NAME_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Input maxLength={32} placeholder={intl.get('INFORMED_NAME_INPUT_PLACEHOLDER')} />
                                                </Form.Item>
                                            </FromItemRowContainer>
                                            <FromItemRowContainer>
                                                <Form.Item name="informedJobPosition" style={{ flexBasis: '300px' }}>
                                                    <Input maxLength={64} placeholder={intl.get('INFORMED_JOB_POSITION_INPUT_PLACEHOLDER')} />
                                                </Form.Item>
                                                <WidthBox />
                                                <Form.Item name="informedLineOfBusiness" style={{ flexBasis: '300px' }}>
                                                    <Input maxLength={64} placeholder={intl.get('INFORMED_LINE_OF_BUSINESS_INPUT_PLACEHOLDER')} />
                                                </Form.Item>
                                            </FromItemRowContainer>
                                            <FromItemRowContainer>
                                                <Form.Item name="informedContactInfo" style={{ flexBasis: '300px' }}>
                                                    <Input maxLength={64} placeholder={intl.get('INFORMED_CONTACT_INFO_INPUT_PLACEHOLDER')} />
                                                </Form.Item>
                                            </FromItemRowContainer>
                                        </FormInformedContainer>

                                        <FormVerificationContainer>
                                            <FromItemTitleContainer>
                                                <FromItemTitle>{intl.get('FORM_ITEM_TITLE_VERIFICATION')}</FromItemTitle>
                                                <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                            </FromItemTitleContainer>

                                            <HeightBox />

                                            <VerificationContainer>
                                                <VerificationInputContainer>
                                                    <Form.Item
                                                        name="kaptchaCode"
                                                        // rules={[
                                                        //     { required: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` },
                                                        //     { whitespace: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` }
                                                        // ]}
                                                        validateStatus={this.state.kaptchaCodeValidateStatus}
                                                        help={this.state.kaptchaCodeValidateHelp}>
                                                        <Input
                                                            className="kaptcha-code-input"
                                                            maxLength={4}
                                                            placeholder={intl.get('VERIFICATION_PLACEHOLDER')}
                                                            onChange={this.onChangeKaptchaCode} />
                                                    </Form.Item>
                                                </VerificationInputContainer>
                                                <VerificationImageContainer>
                                                    <Image
                                                        preview={false}
                                                        width={80}
                                                        height={32}
                                                        style={{ marginRight: 10 }}
                                                        src={this.state.kaptchaImageString} />
                                                    <RefreshIcon
                                                        className="cursor-pointer"
                                                        onClick={this.onClickRefreshKaptchaCode} />
                                                </VerificationImageContainer>
                                            </VerificationContainer>
                                        </FormVerificationContainer>

                                        <BottomImformMessage>{intl.get('INFORM_MESSAGE')}</BottomImformMessage>

                                        <FormSubmitContainer>
                                            <Form.Item style={{ flexBasis: '190px' }}>
                                                <Button className="submit-button" shape="round" type="primary" htmlType="submit">{intl.get('BUTTON_SUBMIT')}</Button>
                                            </Form.Item>
                                        </FormSubmitContainer>
                                    </Form>
                                </CreateInformContentContainer>
                                <ExtraInformContentContainer style={{ display: (!this.state.isInCreateInform ? "block" : "none") }}>
                                    <DisclaimerContainer>
                                        <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL1')}</DisclaimerLabel>
                                        <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL2')}</DisclaimerLabel>
                                    </DisclaimerContainer>
                                    <Divider style={{ marginTop: 20 }} dashed />
                                    <Form
                                        scrollToFirstError={true}
                                        onFinish={this.onFinishExtraInform}
                                        onFinishFailed={this.onFinishFailedExtraInform}>
                                        <FormInformNumberContainer>
                                            <FromItemTitleContainer>
                                                <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORM_NUMBER')}</FromItemTitle>
                                                <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                            </FromItemTitleContainer>
                                            <HeightBox />
                                            <Form.Item
                                                name="informNumberForExtra"
                                                // rules={[
                                                //     { required: true, message: `${intl.get('INFORM_NUMBER_REQUIRED_MESSAGE')}` },
                                                //     { whitespace: true, message: `${intl.get('INFORM_NUMBER_REQUIRED_MESSAGE')}` }
                                                // ]}
                                                validateStatus={this.state.informNumberValidateStatus}
                                                help={intl.get('INFORM_NUMBER_ERROR_MESSAGE')}>
                                                <InformNumberInputContainer>
                                                    <Input
                                                        maxLength={6}
                                                        placeholder={intl.get('INFORM_NUMBER_INPUT_PLACEHOLDER')}
                                                        onChange={this.onChangeInformNumber} />
                                                    <CheckIcon className="cursor-pointer" onClick={this.onClickCheckInformNumberIcon} />
                                                </InformNumberInputContainer>
                                            </Form.Item>
                                        </FormInformNumberContainer>
                                        {
                                            this.state.hasCheckedInformNumber && (
                                                <FormInformExtraMoreContainer>
                                                    <FormInformExtraInfoContainer>
                                                        <FromItemTitleContainer>
                                                            <FromItemTitle>{intl.get('FORM_ITEM_TITLE_EXTRA_INFO')}</FromItemTitle>
                                                            <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                                        </FromItemTitleContainer>
                                                        <HeightBox />
                                                        <FromItemSubTitle>{intl.get('INFORM_INFO_INFORM_EVENT_DESCRIPTION')}</FromItemSubTitle>
                                                        <InformEventHint>{intl.get('INFORM_INFO_INFORM_EVENT_HINT')}</InformEventHint>

                                                        <Form.Item
                                                            name="informContentForExtra"
                                                            rules={[
                                                                { required: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` },
                                                                { whitespace: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` }
                                                            ]}>
                                                            <Input.TextArea maxLength={2048} className="inform-content-input-textarea" autoSize={false} />
                                                        </Form.Item>

                                                        {
                                                            this.state.isGetSASDone && (
                                                                <Form.Item name="files">
                                                                    <Upload fileList={this.state.fileListForExtra} customRequest={this.handleCustomRequestForExtra}>
                                                                        <FormInfoUploadContainer className="cursor-pointer">
                                                                            <UploadSectionLeftContainer>
                                                                                <UploadFolderIcon />
                                                                                <UploadHint>{intl.get('INFORM_INFO_UPLOAD_FILE_LABEL')}</UploadHint>
                                                                            </UploadSectionLeftContainer>
                                                                            <UploadSectionRightContainer>
                                                                                <UploadButtonIcon />
                                                                            </UploadSectionRightContainer>
                                                                        </FormInfoUploadContainer>
                                                                    </Upload>
                                                                </Form.Item>
                                                            )
                                                        }
                                                        <UploadSupportFileListExpand pc={true} />
                                                        <UploadFileListContainer>
                                                            {
                                                                this.state.fileListForExtra.length > 0 && (
                                                                    <List
                                                                        grid={{ gutter: 10, column: 2 }}
                                                                        size="small"
                                                                        dataSource={this.state.fileListForExtra}
                                                                        renderItem={item =>
                                                                            <List.Item>
                                                                                <FileListItemContainer className={item.status !== "error" ? "success" : "error"}>
                                                                                    <FileListItemLeftContainer>
                                                                                        <FileTypeIcon className={[item.formatType, item.status !== "error" ? "success" : "error"]} />
                                                                                        <FileListItemName>{item.name}</FileListItemName>
                                                                                    </FileListItemLeftContainer>
                                                                                    <FileListItemRightContainer>
                                                                                        {
                                                                                            item.status === "loading" ?
                                                                                                <LoadingOutlined style={{ fontSize: '20px' }} />
                                                                                                :
                                                                                                <DeleteIcon onClick={this.onClickFileListForExtraDeleteIcon.bind(this, item.generatedFileName)} />
                                                                                        }
                                                                                    </FileListItemRightContainer>
                                                                                </FileListItemContainer>
                                                                                {
                                                                                    item.status === "error" && (
                                                                                        <FileListItemErrorMessage>{intl.get('FILE_LIST_ITEM_UPLOAD_ERROR_MESSAGE')}</FileListItemErrorMessage>
                                                                                    )
                                                                                }
                                                                            </List.Item>} />
                                                                )
                                                            }
                                                        </UploadFileListContainer>
                                                    </FormInformExtraInfoContainer>

                                                    <FormVerificationContainer>
                                                        <FromItemTitleContainer>
                                                            <FromItemTitle>{intl.get('FORM_ITEM_TITLE_VERIFICATION')}</FromItemTitle>
                                                            <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                                        </FromItemTitleContainer>
                                                        <HeightBox />
                                                        <VerificationContainer>
                                                            <VerificationInputContainer>
                                                                <Form.Item
                                                                    name="kaptchaCodeForExtra"
                                                                    // rules={[
                                                                    //     { required: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` },
                                                                    //     { whitespace: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` }
                                                                    // ]}
                                                                    validateStatus={this.state.kaptchaCodeForExtraValidateStatus}
                                                                    help={this.state.kaptchaCodeForExtraValidateHelp}>
                                                                    <Input
                                                                        className="kaptcha-code-input"
                                                                        maxLength={4}
                                                                        placeholder={intl.get('VERIFICATION_PLACEHOLDER')}
                                                                        onChange={this.onChangeKaptchaCodeForExtra} />
                                                                </Form.Item>
                                                            </VerificationInputContainer>
                                                            <VerificationImageContainer>
                                                                <Image
                                                                    preview={false}
                                                                    width={80}
                                                                    height={32}
                                                                    style={{ marginRight: 10 }}
                                                                    src={this.state.kaptchaImageString} />
                                                                <RefreshIcon
                                                                    className="cursor-pointer"
                                                                    onClick={this.onClickRefreshKaptchaCode} />
                                                            </VerificationImageContainer>
                                                        </VerificationContainer>
                                                    </FormVerificationContainer>

                                                    <FormSubmitContainer>
                                                        <Form.Item style={{ flexBasis: '190px' }}>
                                                            <Button className="submit-button" shape="round" type="primary" htmlType="submit">{intl.get('BUTTON_SUBMIT')}</Button>
                                                        </Form.Item>
                                                    </FormSubmitContainer>
                                                </FormInformExtraMoreContainer>
                                            )
                                        }
                                    </Form>
                                </ExtraInformContentContainer>
                            </TabContentContainer>
                        }
                    </FromContentContainer>
                    <Modal
                        maskClosable={false}
                        centered={true}
                        visible={this.state.isErrorMessageModalVisible}
                        footer={null}
                        onCancel={this.closeErrorMessageModal}
                        width={550}>
                        <ErrorMessageContainer>
                            <ErrorMessageLabel>{this.state.errorMessage}</ErrorMessageLabel>
                        </ErrorMessageContainer>
                    </Modal>
                </FormSectionContainer>
            </PcFormContainer>
        )
    }
}

export default withRouter(PcForm)
