import React, { Component } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import styled from 'styled-components'
import { BlobServiceClient } from '@azure/storage-blob'
import intl from 'react-intl-universal'
import { Form, Radio, Select, Input, Upload, Button, Image, Modal, List } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ccmsService from '../services/ccmsService'
import { checkFile, getFormatFile, getFormatInformRequset, getFormatExtraInformRequset } from '../common/utils'
import { FILE_CHECK_RESULT_TYPE_ERROR, FILE_CHECK_RESULT_SIZE_ERROR, INFORMER_SECURITY_TYPE_ENUM } from '../common/const'
import { ArrowDownIcon, UploadButtonIcon, UploadFolderIcon, DeleteIcon, FileTypeIcon, CheckIcon, RefreshIcon } from '../common/icon'
import UploadSupportFileListExpand from '../components/UploadSupportFileListExpand'

const { Option } = Select

const MobileFormContainer = styled.div`
    display: flex;
    flex-direction: column;

    .ant-form-item {
        text-align: left;
        margin-bottom: 10px;
    }

    .ant-upload {
        width: 100%;
    }
`

const FormHeader = styled.div`
    height: 10px;
    width: 100vw;
    background-color: #00182e;
    z-index: 9;
    margin-top: -1px;
`

const FormSectionContainer = styled.div`
    width: 100vw;
    padding: 0 16px;
    margin-top: -9px;
    z-index: 10;
`

const FormTabsContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 0 0 20px;
`

const FormTabs = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    text-align: center;
`

const TabItem = styled.div`
    width: 50%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const TabName = styled.div`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #9e9e9e;
    line-height: 1;

    &.active {
        font-size: 18px;
        font-weight: bold;
        color: #05206b;
    }
`

const TabBorder = styled.div`
    width: 70px;
    height: 4px;
    background-color: #1890ff;
    display: flex;
    border-radius: 2px;
`

const DisclaimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 16px 0;
`

const DisclaimerLabel = styled.div`
    color: #bd0017;
    text-align: left;
    font-weight: bold;
`

const ImformMessage = styled.p`
    margin: 8px 16px 0;
    text-align: left;
    font-weight: bold;
`

const TabContentContainer = styled.div`
`

const CreateInformContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ExtraInformContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FormInformerContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px 1px;
`

const FromItemTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    text-align: left;
`

const FromItemSubTitle = styled.p`
    text-align: left;
`

const RequiredHint = styled.p`
    text-align: left;
    font-size: 12px;
    opacity: 0.8;
`

const HeightBox = styled.div`
    height: 18px;
`

const RadioButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const RadioButtonItem = styled.div`
    display: flex;
`

const FormInformInfoContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px 20px;
`

const InformEventHint = styled.p`
    text-align: left;
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 6px;
`

const FormInfoUploadContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background-color: #f9fcff;
    border: 1px solid #e6eff8;
    border-radius: 8px;
    padding: 0 12px;
`

const UploadSectionLeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const UploadSectionRightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const UploadHint = styled.div`
    color: #9e9e9e;
    margin-left: 10px;
`

const UploadFileListContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FormInformedContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px 1px;
`

const FormVerificationContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px 1px;
`

const FormPromptContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px;
`

const FormPrompt = styled.p`
    text-align: left;
    font-weight: bold;
`

const FileListItemContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #ffc836;
    background-color: #fffdf9;
    border: 1px solid #feedcd;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 14px;

    &.error {
        color: #bd0017;
        background-color: #fff9f9;
        border: 1px solid #f3aaaa;
    }
`

const FileListItemLeftContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-start;
`

const FileListItemRightContainer = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FileListItemName = styled.div`
    font-size: 12px;
    text-align: left;
    margin-left: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
`

const FileListItemErrorMessage = styled.div`
    color: #bd0017;
    font-size: 12px;
    text-align: left;
`

const VerificationContainer = styled.div`
    display: flex;
`

const VerificationInputContainer = styled.div`
    width: 50%;
`

const VerificationImageContainer = styled.div`
    width: 50%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const FormSubmitContainer = styled.div`
    margin: 30px 0;
`

const FormInformNumberContainer = styled.div`
    background-color: white;
    border-radius: 0 0 10px 10px;
    margin-top: -10px;
    padding: 10px 16px 10px;
`

const InformNumberInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FormInformExtraMoreContainer = styled.div`
`

const FormInformExtraInfoContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px 16px 20px;
`

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
    overflow: auto;
`

const ErrorMessageLabel = styled.div`
    font-size: 16px;
    text-align: center;
`

class MobileForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            containerClient: Object,
            isGetSASDone: false,
            isInCreateInform: true,
            informerSecurityType: true,
            isUploadSupportFileListExpand: false,
            isUploadSupportFileListExpandForExtra: false,
            fileList: [],
            fileListForExtra: [],
            isInformerTypeCommentVisable: false,
            isInformTypeCommentVisable: false,
            isInformedTypeCommentVisable: false,
            kaptchaImageString: '',
            kaptchaId: '',
            informNumber: '',
            hasCheckedInformNumber: false,
            kaptchaCodeValidateStatus: 'success',
            kaptchaCodeValidateHelp: '',
            informNumberValidateStatus: 'success',
            kaptchaCodeForExtraValidateStatus: 'success',
            kaptchaCodeForExtraValidateHelp: '',
            isErrorMessageModalVisible: false,
            errorMessage: '',
            isGoBack: true
        }
    }

    componentDidMount() {
        const previousPage = sessionStorage.getItem('previousPage')
        if (previousPage === 'notice' || previousPage === 'success') {
            document.body.scrollIntoView()
            this.getSAS()
            this.getKaptchaCode({ kaptchaId: undefined })
        } else {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: '/home' })
            })
        }
    }

    async getSAS() {
        var response = await ccmsService.getSAS()
        if (response.code === 200) {
            let accoutName = response.data.accoutName
            let endPorintStuffix = response.data.endPorintStuffix
            let token = response.data.token
            let containerName = response.data.containerName

            const blobSasUrl = `https://${accoutName}.blob.${endPorintStuffix}/?${token}`
            const blobServiceClient = new BlobServiceClient(blobSasUrl)

            this.setState({
                containerClient: blobServiceClient.getContainerClient(containerName),
                isGetSASDone: true,
            })
        }
    }

    getKaptchaCode = async (request) => {
        var response = await ccmsService.getKaptchaCode(request)
        if (response.code === 200) {
            this.setState({
                kaptchaImageString: response.data.imageString,
                kaptchaId: response.data.kaptchaId
            })
        }
    }

    checkIsFileValid = async (file, fileList) => {
        // let duplicateFile = fileList.filter(item => { return item.name === file.name })
        // if (duplicateFile.length > 0) {
        //     this.setState({
        //         errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_DUPLICATE_ERROR'),
        //         isErrorMessageModalVisible: true
        //     })
        //     return false
        // }
        if (fileList.length >= 9) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_COUNT_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        }

        let checkResult = await checkFile(file)

        if (checkResult === FILE_CHECK_RESULT_TYPE_ERROR) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_TYPE_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        } else if (checkResult === FILE_CHECK_RESULT_SIZE_ERROR) {
            this.setState({
                errorMessage: intl.get('ERROR_MESSAGE_SELECE_FILE_SIZE_ERROR'),
                isErrorMessageModalVisible: true
            })
            return false
        }

        return true
    }

    onChangeTabs = (key) => {
        if (key === 'createInform') {
            this.setState({ isInCreateInform: true })
        } else {
            this.setState({ isInCreateInform: false })
        }
    }

    onChangeInformerSecurityType = e => {
        this.setState({ informerSecurityType: e.target.value })
    }

    onChangeInformerType = (value) => {
        if (value === 'other') {
            this.setState({ isInformerTypeCommentVisable: true })
        } else {
            this.setState({ isInformerTypeCommentVisable: false })
        }
    }

    onChangeInformType = (value) => {
        if (value === 'complianceInformOther') {
            this.setState({ isInformTypeCommentVisable: true })
        } else {
            this.setState({ isInformTypeCommentVisable: false })
        }
    }

    onChangeInformedType = (value) => {
        if (value === 'other') {
            this.setState({ isInformedTypeCommentVisable: true })
        } else {
            this.setState({ isInformedTypeCommentVisable: false })
        }
    }

    onCLickUploadSupportFileListHeader = () => {
        this.setState({ isUploadSupportFileListExpand: !this.state.isUploadSupportFileListExpand })
    }

    onClickFileListDeleteIcon = (generatedFileName) => {
        this.state.fileList.forEach((item, index) => {
            if (item.generatedFileName === generatedFileName) {
                this.state.fileList.splice(index, 1)
                this.setState(state => ({ fileList: [...state.fileList] }))
            }
        })
    }

    handleCustomRequest = obj => {
        this.uploadFile(obj.file, 'createInform')
    }

    uploadFile = async (file, type) => {
        let isCreateInform = type === 'createInform'
        let currnetFileList = isCreateInform ? this.state.fileList : this.state.fileListForExtra

        if (!await this.checkIsFileValid(file, currnetFileList)) return false

        let formatFile = getFormatFile(file)
        formatFile.status = 'loading'
        if (isCreateInform) {
            this.setState(state => ({ fileList: [...state.fileList, formatFile] }))
        } else {
            this.setState(state => ({ fileListForExtra: [...state.fileListForExtra, formatFile] }))
        }

        try {
            const promises = []
            const blockBlobClient = this.state.containerClient.getBlockBlobClient(formatFile.generatedFileName)
            promises.push(blockBlobClient.uploadBrowserData(formatFile))
            await Promise.all(promises).then(() => {
                formatFile.status = 'done'
                if (isCreateInform) {
                    this.setState(state => ({ fileList: [...state.fileList] }))
                } else {
                    this.setState(state => ({ fileListForExtra: [...state.fileListForExtra] }))
                }
            })
        }
        catch (error) {
            formatFile.status = 'error'
            if (isCreateInform) {
                this.setState(state => ({ fileList: [...state.fileList] }))
            } else {
                this.setState(state => ({ fileListForExtra: [...state.fileListForExtra] }))
            }
        }
    }

    onChangeKaptchaCode = () => {
        this.setState({
            kaptchaCodeValidateStatus: 'success',
            kaptchaCodeValidateHelp: ''
        })
    }

    onClickRefreshKaptchaCode = () => {
        this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
    }

    onFinishCreateInform = values => {
        if (this.checkIsValuesValid(values)) {
            this.submitInform(values)
        }
    }

    onFinishFailedCreateInform = obj => {
        let kaptchaCode = obj.values.kaptchaCode
        if (!kaptchaCode || kaptchaCode.trim() === '') {
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        } else {
            this.setState({
                kaptchaCodeValidateStatus: 'success',
                kaptchaCodeValidateHelp: ''
            })
        }
    }

    checkIsValuesValid = values => {
        let isValid = true
        if (values.kaptchaCode && values.kaptchaCode.trim() !== '') {
            this.setState({
                kaptchaCodeValidateStatus: 'success',
                kaptchaCodeValidateHelp: ''
            })
        } else {
            isValid = false
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        }

        return isValid
    }

    async submitInform(values) {
        let request = getFormatInformRequset(values, this.state.fileList, this.state.kaptchaId)
        var response = await ccmsService.submitInform(request)
        if (response.code === 200) {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: `/success/${response.data.informNumber}` })
            })
        } else if (response.code === 1002) {
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 1003) {
            this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
            this.setState({
                kaptchaCodeValidateStatus: 'error',
                kaptchaCodeValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 4001) {
          this.setState({
            errorMessage: intl.get('ERROR_MESSAGE_INPUT_VALID'),
            isErrorMessageModalVisible: true
          })
        }
    }

    onChangeInformNumber = e => {
        this.setState({ informNumber: e.target.value })
    }

    onClickCheckInformNumberIcon = async () => {
        let informNumber = this.state.informNumber.trim()
        var response = await ccmsService.checkInformNumber(informNumber)
        if (response.code === 200) {
            this.setState({
                hasCheckedInformNumber: true,
                informNumberValidateStatus: 'success'
            })
        } else if (response.code === 1001) {
            this.setState({
                hasCheckedInformNumber: false,
                informNumberValidateStatus: 'error'
            })
        }
    }

    onClickFileListForExtraDeleteIcon = (generatedFileName) => {
        this.state.fileListForExtra.forEach((item, index) => {
            if (item.generatedFileName === generatedFileName) {
                this.state.fileListForExtra.splice(index, 1)
                this.setState(state => ({
                    fileListForExtra: [...state.fileListForExtra],
                }))
            }
        })
    }

    handleCustomRequestForExtra = obj => {
        this.uploadFile(obj.file, 'extraInform')
    }

    onCLickUploadSupportFileListHeaderForExtra = () => {
        this.setState({ isUploadSupportFileListExpandForExtra: !this.state.isUploadSupportFileListExpandForExtra })
    }

    onChangeKaptchaCodeForExtra = () => {
        this.setState({
            kaptchaCodeForExtraValidateStatus: 'success',
            kaptchaCodeForExtraValidateHelp: '',
        })
    }

    onFinishExtraInform = values => {
        if (this.checkIsValuesValidForExtra(values)) {
            this.submitInformForExtra(values)
        }
    }

    onFinishFailedExtraInform = obj => {
        let kaptchaCodeForExtra = obj.values.kaptchaCodeForExtra
        if (!kaptchaCodeForExtra || kaptchaCodeForExtra.trim() === '') {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        } else {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'success',
                kaptchaCodeForExtraValidateHelp: ''
            })
        }
    }

    checkIsValuesValidForExtra = values => {
        let isValid = true
        if (values.kaptchaCodeForExtra && values.kaptchaCodeForExtra.trim() !== '') {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'success',
                kaptchaCodeForExtraValidateHelp: ''
            })
        } else {
            isValid = false
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_REQUIRED_MESSAGE')
            })
        }

        return isValid
    }

    async submitInformForExtra(values) {
        let request = getFormatExtraInformRequset(values, this.state.fileListForExtra, this.state.kaptchaId)
        var response = await ccmsService.submitInformExtra(request)
        if (response.code === 200) {
            this.setState({ isGoBack: false }, () => {
                sessionStorage.setItem('previousPage', 'form')
                this.props.history.replace({ pathname: `/success/${response.data.informNumber}` })
            })
        } else if (response.code === 1001) {
            this.setState({ informNumberValidateStatus: 'error' })
        } else if (response.code === 1002) {
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 1003) {
            this.getKaptchaCode({ kaptchaId: this.state.kaptchaId })
            this.setState({
                kaptchaCodeForExtraValidateStatus: 'error',
                kaptchaCodeForExtraValidateHelp: intl.get('VERIFICATION_ERROR_MESSAGE')
            })
        } else if (response.code === 4001) {
          this.setState({
            errorMessage: intl.get('ERROR_MESSAGE_INPUT_VALID'),
            isErrorMessageModalVisible: true
          })
        }
    }

    closeErrorMessageModal = () => {
        this.setState({
            errorMessage: '',
            isErrorMessageModalVisible: false
        })
    }

    render() {
        return (
            <MobileFormContainer className="mobile-form-container">
                <Prompt when={this.state.isGoBack} message={intl.get('GO_BACK_HINT')} />
                <FormHeader />
                <FormSectionContainer>
                    <FormTabsContainer>
                        <FormTabs>
                            <TabItem onClick={this.onChangeTabs.bind(this, 'createInform')}>
                                <TabName className={this.state.isInCreateInform ? "active" : null}>{intl.get('CREATE_INFORM')}</TabName>
                                {this.state.isInCreateInform && (<TabBorder />)}
                            </TabItem>

                            <TabItem onClick={this.onChangeTabs.bind(this, 'extraInform')}>
                                <TabName className={!this.state.isInCreateInform ? "active" : null}>{intl.get('EXTRA_INFORM')}</TabName>
                                {!this.state.isInCreateInform && (<TabBorder />)}
                            </TabItem>
                        </FormTabs>
                        <DisclaimerContainer>
                            <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL1')}</DisclaimerLabel>
                            <DisclaimerLabel>{intl.get('DISCLAIMER_LABEL2')}</DisclaimerLabel>
                        </DisclaimerContainer>
                        {
                            this.state.isInCreateInform && (
                                <ImformMessage>{intl.get('INFORM_MESSAGE')}</ImformMessage>
                            )
                        }
                    </FormTabsContainer>
                    {
                        <TabContentContainer>
                            <CreateInformContentContainer style={{ display: (this.state.isInCreateInform ? "block" : "none") }}>
                                <Form
                                    scrollToFirstError={true}
                                    onFinish={this.onFinishCreateInform}
                                    onFinishFailed={this.onFinishFailedCreateInform}>
                                    <FormInformerContainer>
                                        <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORMER')}</FromItemTitle>
                                        <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>

                                        <Form.Item name="informerSecurityType" initialValue={true}>
                                            <Radio.Group onChange={this.onChangeInformerSecurityType} value={this.state.informerSecurityType}>
                                                <RadioButtonContainer >
                                                    <RadioButtonItem>
                                                        <Radio value={INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value}>{intl.get('INFORM_TYPE_REALNAME')}</Radio>
                                                    </RadioButtonItem>
                                                    <RadioButtonItem>
                                                        <Radio value={INFORMER_SECURITY_TYPE_ENUM.ANONYMOUS.value}>{intl.get('INFORM_TYPE_ANONYMOUSLY')}</Radio>
                                                    </RadioButtonItem>
                                                </RadioButtonContainer>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item
                                            name="informerType"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORMER_TYPE_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Select
                                                placeholder={intl.get('INFORMER_TYPE_PLACEHOLDER')}
                                                onChange={this.onChangeInformerType}
                                                allowClear={false}
                                                suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                <Option value="employee">{intl.get('INFORMER_TYPE_EMPLOYEE')}</Option>
                                                <Option value="formerEmployee">{intl.get('INFORMER_TYPE_FORMER_EMPLOYEE')}</Option>
                                                <Option value="supplier">{intl.get('INFORMER_TYPE_SUPPLIER')}</Option>
                                                <Option value="partner">{intl.get('INFORMER_TYPE_PARTNER')}</Option>
                                                <Option value="customer">{intl.get('INFORMER_TYPE_CUSTOMER')}</Option>
                                                <Option value="other">{intl.get('INFORMER_TYPE_OTHER')}</Option>
                                            </Select>
                                        </Form.Item>

                                        {
                                            this.state.isInformerTypeCommentVisable && (
                                                <Form.Item
                                                    name="informerTypeComment"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMER_COMMENT_REQUIRED_MESSAGE')}` },
                                                        { whitespace: true, message: `${intl.get('INFORMER_COMMENT_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Input.TextArea maxLength={256} autoSize={false} placeholder={intl.get('INFORMER_COMMENT_PLACEHOLDER')} style={{ height: 90 }} />
                                                </Form.Item>
                                            )
                                        }

                                        {
                                            this.state.informerSecurityType === INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value ?
                                                <Form.Item
                                                    name="informerNameRequired"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMER_NAME_REQUIRED_MESSAGE')}` },
                                                        { whitespace: true, message: `${intl.get('INFORMER_NAME_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Input maxLength={32} placeholder={intl.get('INFORMER_NAME_REQUIRED_PLACEHOLDER')} />
                                                </Form.Item>
                                                :
                                                <Form.Item name="informerName">
                                                    <Input maxLength={32} placeholder={intl.get('INFORMER_NAME_PLACEHOLDER')} />
                                                </Form.Item>
                                        }

                                        <Form.Item name="informerContactInfo">
                                            <Input maxLength={64} placeholder={intl.get('INFORMER_CONTACT_INFO_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item name="informerOtherContactInfo">
                                            <Input maxLength={64} placeholder={intl.get('INFORMER_OTHER_CONTACT_INFO_PLACEHOLDER')} />
                                        </Form.Item>
                                    </FormInformerContainer>

                                    <FormInformInfoContainer>
                                        <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORM_INFO')}</FromItemTitle>
                                        <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                        <HeightBox />

                                        <Form.Item
                                            name="informRegion"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORM_INFO_INFORM_REGION_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Select
                                                placeholder={intl.get('INFORM_INFO_INFORM_REGION_INPUT_PLACEHOLDER')}
                                                allowClear={false}
                                                suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                    <Option value="mainland">{intl.get('INFORM_REGION_MAINLAND')}</Option>
                                                    <Option value="hk">{intl.get('INFORM_REGION_HK')}</Option>
                                                    <Option value="mo">{intl.get('INFORM_REGION_MO')}</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="informType"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Select
                                                placeholder={intl.get('INFORM_INFO_INFORM_TYPE_SELECT_PLACEHOLDER')}
                                                onChange={this.onChangeInformType}
                                                allowClear={false}
                                                virtual={false}
                                                suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                <Option value="bribe">{intl.get('INFORM_TYPE_BRIBE')}</Option>
                                                <Option value="conflictOfInterest">{intl.get('INFORM_TYPE_CONFLICT_OF_INTEREST')}</Option>
                                                <Option value="counterfeit">{intl.get('INFORM_TYPE_COUNTERFEIT')}</Option>
                                                <Option value="theft">{intl.get('INFORM_TYPE_THEFT')}</Option>
                                                <Option value="giftsAndEntertainmentViolation">{intl.get('INFORM_TYPE_GIFT_AND_ENTERTAINMENT_VIOLATION')}</Option>
                                                <Option value="harassmentOrDiscrimination">{intl.get('INFORM_TYPE_HARASSMENT_OR_DISCRIMINATION')}</Option>
                                                <Option value="leakCompanyTradeSecrets">{intl.get('INFORM_TYPE_LEAK_COMPANY_TRADE_SECRETS')}</Option>
                                                <Option value="dataAndNetworkSecurity">{intl.get('INFORM_TYPE_DATA_AND_NETWORK_SECURITY')}</Option>
                                                <Option value="retaliationAgainstReportingPersonnel">{intl.get('INFORM_TYPE_RETALIATION_AGAINST_REPORTING_PERSONNEL')}</Option>
                                                <Option value="complianceInformOther">{intl.get('INFORM_TYPE_OTHER')}</Option>
                                            </Select>
                                        </Form.Item>

                                        {
                                            this.state.isInformTypeCommentVisable && (
                                                <Form.Item
                                                    name="informTypeComment"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_REQUIRED_MESSAGE')}` },
                                                        { whitespace: true, message: `${intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Input.TextArea maxLength={256} autoSize={false} placeholder={intl.get('INFORM_INFO_INFORM_TYPE_COMMENT_PLACEHOLDER')} style={{ height: 90 }} />
                                                </Form.Item>
                                            )
                                        }

                                        <FromItemSubTitle>{intl.get('INFORM_INFO_INFORM_EVENT_DESCRIPTION')}</FromItemSubTitle>
                                        <InformEventHint>{intl.get('INFORM_INFO_INFORM_EVENT_HINT')}</InformEventHint>
                                        <Form.Item
                                            name="informContent"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` },
                                                { whitespace: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Input.TextArea maxLength={2048} autoSize={false} style={{ height: 90 }} />
                                        </Form.Item>

                                        {
                                            this.state.isGetSASDone && (
                                                <Form.Item name="informFiles">
                                                    <Upload fileList={this.state.fileList} customRequest={this.handleCustomRequest}>
                                                        <FormInfoUploadContainer>
                                                            <UploadSectionLeftContainer>
                                                                <UploadFolderIcon />
                                                                <UploadHint>{intl.get('INFORM_INFO_UPLOAD_FILE_LABEL')}</UploadHint>
                                                            </UploadSectionLeftContainer>
                                                            <UploadSectionRightContainer>
                                                                <UploadButtonIcon />
                                                            </UploadSectionRightContainer>
                                                        </FormInfoUploadContainer>
                                                    </Upload>
                                                </Form.Item>
                                            )
                                        }
                                        <UploadSupportFileListExpand />
                                        <UploadFileListContainer>
                                            {
                                                this.state.fileList.length > 0 && (
                                                    <List
                                                        size="small"
                                                        dataSource={this.state.fileList}
                                                        renderItem={item =>
                                                            <List.Item>
                                                                <FileListItemContainer className={item.status !== "error" ? "success" : "error"}>
                                                                    <FileListItemLeftContainer>
                                                                        <FileTypeIcon className={[item.formatType, item.status !== "error" ? "success" : "error"]} />
                                                                        <FileListItemName>{item.name}</FileListItemName>
                                                                    </FileListItemLeftContainer>
                                                                    <FileListItemRightContainer>
                                                                        {
                                                                            item.status === "loading" ?
                                                                                <LoadingOutlined style={{ fontSize: '20px' }} />
                                                                                :
                                                                                <DeleteIcon onClick={this.onClickFileListDeleteIcon.bind(this, item.generatedFileName)} />
                                                                        }
                                                                    </FileListItemRightContainer>
                                                                </FileListItemContainer>
                                                                {
                                                                    item.status === "error" && (
                                                                        <FileListItemErrorMessage>{intl.get('FILE_LIST_ITEM_UPLOAD_ERROR_MESSAGE')}</FileListItemErrorMessage>
                                                                    )
                                                                }
                                                            </List.Item>} />
                                                )
                                            }
                                        </UploadFileListContainer>
                                    </FormInformInfoContainer>

                                    <FormInformedContainer>
                                        <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORMED')}</FromItemTitle>
                                        <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                        <HeightBox />

                                        <Form.Item
                                            name="informedType"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORMED_TYPE_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Select
                                                placeholder={intl.get('INFORMED_TYPE_SELECT_PLACEHOLDER')}
                                                onChange={this.onChangeInformedType}
                                                allowClear={false}
                                                suffixIcon={<ArrowDownIcon style={{ marginTop: -6 }} />}>
                                                <Option value="employee">{intl.get('INFORMED_TYPE_EMPLOYEE')}</Option>
                                                <Option value="supplier">{intl.get('INFORMED_TYPE_SUPPLIER')}</Option>
                                                <Option value="franchisee">{intl.get('INFORMED_TYPE_RRANCHISEE')}</Option>
                                                <Option value="partner">{intl.get('INFORMED_TYPE_PARTNER')}</Option>
                                                <Option value="other">{intl.get('INFORMED_TYPE_OTHERS')}</Option>
                                            </Select>
                                        </Form.Item>

                                        {
                                            this.state.isInformedTypeCommentVisable && (
                                                <Form.Item
                                                    name="informedTypeComment"
                                                    rules={[
                                                        { required: true, message: `${intl.get('INFORMED_COMMENT_REQUIRED_MESSAGE')}` },
                                                        { whitespace: true, message: `${intl.get('INFORMED_COMMENT_REQUIRED_MESSAGE')}` }
                                                    ]}>
                                                    <Input.TextArea maxLength={256} autoSize={false} placeholder={intl.get('INFORMED_COMMENT_PLACEHOLDER')} style={{ height: 90 }} />
                                                </Form.Item>
                                            )
                                        }

                                        <Form.Item
                                            name="informedName"
                                            rules={[
                                                { required: true, message: `${intl.get('INFORMED_NAME_REQUIRED_MESSAGE')}` },
                                                { whitespace: true, message: `${intl.get('INFORMED_NAME_REQUIRED_MESSAGE')}` }
                                            ]}>
                                            <Input maxLength={32} placeholder={intl.get('INFORMED_NAME_INPUT_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item name="informedJobPosition">
                                            <Input maxLength={64} placeholder={intl.get('INFORMED_JOB_POSITION_INPUT_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item name="informedLineOfBusiness">
                                            <Input maxLength={64} placeholder={intl.get('INFORMED_LINE_OF_BUSINESS_INPUT_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item name="informedContactInfo">
                                            <Input maxLength={64} placeholder={intl.get('INFORMED_CONTACT_INFO_INPUT_PLACEHOLDER')} />
                                        </Form.Item>
                                    </FormInformedContainer>

                                    <FormVerificationContainer>
                                        <FromItemTitle>{intl.get('FORM_ITEM_TITLE_VERIFICATION')}</FromItemTitle>
                                        <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                        <HeightBox />

                                        <VerificationContainer>
                                            <VerificationInputContainer>
                                                <Form.Item
                                                    name="kaptchaCode"
                                                    // rules={[
                                                    //     { required: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` },
                                                    //     { whitespace: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` }
                                                    // ]}
                                                    validateStatus={this.state.kaptchaCodeValidateStatus}
                                                    help={this.state.kaptchaCodeValidateHelp}>
                                                    <Input
                                                        maxLength={4}
                                                        placeholder={intl.get('VERIFICATION_PLACEHOLDER')}
                                                        onChange={this.onChangeKaptchaCode} />
                                                </Form.Item>
                                            </VerificationInputContainer>
                                            <VerificationImageContainer>
                                                <Image preview={false} width={80} height={32} src={this.state.kaptchaImageString} />
                                                <RefreshIcon onClick={this.onClickRefreshKaptchaCode} />
                                            </VerificationImageContainer>
                                        </VerificationContainer>
                                    </FormVerificationContainer>

                                    <FormPromptContainer>
                                        <FormPrompt>{intl.get('INFORM_MESSAGE')}</FormPrompt>
                                    </FormPromptContainer>

                                    <FormSubmitContainer>
                                        <Form.Item>
                                            <Button block shape="round" type="primary" htmlType="submit">{intl.get('BUTTON_SUBMIT')}</Button>
                                        </Form.Item>
                                    </FormSubmitContainer>
                                </Form>
                            </CreateInformContentContainer>
                            <ExtraInformContentContainer style={{ display: (!this.state.isInCreateInform ? "block" : "none") }}>
                                <Form
                                    scrollToFirstError={true}
                                    onFinish={this.onFinishExtraInform}
                                    onFinishFailed={this.onFinishFailedExtraInform}>
                                    <FormInformNumberContainer>
                                        <FromItemTitle>{intl.get('FORM_ITEM_TITLE_INFORM_NUMBER')}</FromItemTitle>
                                        <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                        <HeightBox />

                                        <Form.Item
                                            name="informNumberForExtra"
                                            // rules={[
                                            //     { required: true, message: `${intl.get('INFORM_NUMBER_REQUIRED_MESSAGE')}` },
                                            //     { whitespace: true, message: `${intl.get('INFORM_NUMBER_REQUIRED_MESSAGE')}` }
                                            // ]}
                                            validateStatus={this.state.informNumberValidateStatus}
                                            help={intl.get('INFORM_NUMBER_ERROR_MESSAGE')}>
                                            <InformNumberInputContainer>
                                                <Input maxLength={6} placeholder={intl.get('INFORM_NUMBER_INPUT_PLACEHOLDER')} onChange={this.onChangeInformNumber} />
                                                <CheckIcon onClick={this.onClickCheckInformNumberIcon} />
                                            </InformNumberInputContainer>
                                        </Form.Item>
                                    </FormInformNumberContainer>
                                    {
                                        this.state.hasCheckedInformNumber && (
                                            <FormInformExtraMoreContainer>
                                                <FormInformExtraInfoContainer>
                                                    <FromItemTitle>{intl.get('FORM_ITEM_TITLE_EXTRA_INFO')}</FromItemTitle>
                                                    <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                                    <HeightBox />
                                                    <FromItemSubTitle>{intl.get('INFORM_INFO_INFORM_EVENT_DESCRIPTION')}</FromItemSubTitle>
                                                    <InformEventHint>{intl.get('INFORM_INFO_INFORM_EVENT_HINT')}</InformEventHint>

                                                    <Form.Item
                                                        name="informContentForExtra"
                                                        rules={[
                                                            { required: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` },
                                                            { whitespace: true, message: `${intl.get('INFORM_INFO_VIOLATIONS_REQUIRED_MESSAGE')}` }
                                                        ]}>
                                                        <Input.TextArea maxLength={2048} autoSize={false} style={{ height: 90 }} />
                                                    </Form.Item>

                                                    {
                                                        this.state.isGetSASDone && (
                                                            <Form.Item name="informFilesForExtra">
                                                                <Upload fileList={this.state.fileListForExtra} customRequest={this.handleCustomRequestForExtra}>
                                                                    <FormInfoUploadContainer>
                                                                        <UploadSectionLeftContainer>
                                                                            <UploadFolderIcon />
                                                                            <UploadHint>{intl.get('INFORM_INFO_UPLOAD_FILE_LABEL')}</UploadHint>
                                                                        </UploadSectionLeftContainer>
                                                                        <UploadSectionRightContainer>
                                                                            <UploadButtonIcon />
                                                                        </UploadSectionRightContainer>
                                                                    </FormInfoUploadContainer>
                                                                </Upload>
                                                            </Form.Item>
                                                        )
                                                    }
                                                    <UploadSupportFileListExpand />
                                                    <UploadFileListContainer>
                                                        {
                                                            this.state.fileListForExtra.length > 0 && (
                                                                <List
                                                                    size="small"
                                                                    dataSource={this.state.fileListForExtra}
                                                                    renderItem={item =>
                                                                        <List.Item>
                                                                            <FileListItemContainer className={item.status !== "error" ? "success" : "error"}>
                                                                                <FileListItemLeftContainer>
                                                                                    <FileTypeIcon className={[item.formatType, item.status !== "error" ? "success" : "error"]} />
                                                                                    <FileListItemName>{item.name}</FileListItemName>
                                                                                </FileListItemLeftContainer>
                                                                                <FileListItemRightContainer>
                                                                                    {
                                                                                        item.status === "loading" ?
                                                                                            <LoadingOutlined style={{ fontSize: '20px' }} />
                                                                                            :
                                                                                            <DeleteIcon onClick={this.onClickFileListForExtraDeleteIcon.bind(this, item.generatedFileName)} />
                                                                                    }
                                                                                </FileListItemRightContainer>
                                                                            </FileListItemContainer>
                                                                            {
                                                                                item.status === "error" && (
                                                                                    <FileListItemErrorMessage>{intl.get('FILE_LIST_ITEM_UPLOAD_ERROR_MESSAGE')}</FileListItemErrorMessage>
                                                                                )
                                                                            }
                                                                        </List.Item>} />
                                                            )
                                                        }
                                                    </UploadFileListContainer>
                                                </FormInformExtraInfoContainer>

                                                <FormVerificationContainer>
                                                    <FromItemTitle>{intl.get('FORM_ITEM_TITLE_VERIFICATION')}</FromItemTitle>
                                                    <RequiredHint>{intl.get('REQUIRED_HINT')}</RequiredHint>
                                                    <HeightBox />

                                                    <VerificationContainer>
                                                        <VerificationInputContainer>
                                                            <Form.Item
                                                                name="kaptchaCodeForExtra"
                                                                // rules={[
                                                                //     { required: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` },
                                                                //     { whitespace: true, message: `${intl.get('VERIFICATION_REQUIRED_MESSAGE')}` }
                                                                // ]}
                                                                validateStatus={this.state.kaptchaCodeForExtraValidateStatus}
                                                                help={this.state.kaptchaCodeForExtraValidateHelp}>
                                                                <Input maxLength={4} placeholder={intl.get('VERIFICATION_PLACEHOLDER')} onChange={this.onChangeKaptchaCodeForExtra} />
                                                            </Form.Item>
                                                        </VerificationInputContainer>
                                                        <VerificationImageContainer>
                                                            <Image preview={false} width={80} height={32} src={this.state.kaptchaImageString} />
                                                            <RefreshIcon onClick={this.onClickRefreshKaptchaCode} />
                                                        </VerificationImageContainer>
                                                    </VerificationContainer>
                                                </FormVerificationContainer>

                                                <FormSubmitContainer>
                                                    <Form.Item>
                                                        <Button block shape="round" type="primary" htmlType="submit">{intl.get('BUTTON_SUBMIT')}</Button>
                                                    </Form.Item>
                                                </FormSubmitContainer>
                                            </FormInformExtraMoreContainer>
                                        )
                                    }
                                </Form>
                            </ExtraInformContentContainer>
                        </TabContentContainer>
                    }
                    <Modal
                        maskClosable={false}
                        centered={true}
                        visible={this.state.isErrorMessageModalVisible}
                        footer={null}
                        onCancel={this.closeErrorMessageModal}
                        width={300}>
                        <ErrorMessageContainer>
                            <ErrorMessageLabel>{this.state.errorMessage}</ErrorMessageLabel>
                        </ErrorMessageContainer>
                    </Modal>
                </FormSectionContainer>
            </MobileFormContainer>
        )
    }
}

export default withRouter(MobileForm)
