import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import PDFView from '../../components/PDFView'
import ccmsService from '../../services/ccmsService'
import azureService from '../../services/azureService'
import { COMPLIANCE_NEWS_CONTENT_TYPE } from '../../common/const'
import wechatShareService from '../../services/wechatShareService'
import { formatDate, checkIsLoadWatermark } from '../../common/utils'

import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            isPreview: false,  // 是否为预览请求
            compliancePolicyDetail: {},
            pdfContentUrl: '',
            isPdf: false,
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.closeWindow)
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))

        const { id } = this.props.match.params;
        const searchParams = new URLSearchParams(window.location.search)
        const isPreview = searchParams.get('isPreview')
        this.setState({id, isPreview: isPreview === 'true' || false}, () => {
            this.getCompliancePolicyDetail()
        })
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.closeWindow)
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    getCompliancePolicyDetail = async () => {
        let response = null
        if (this.state.isPreview) {
            response = await ccmsService.getPreviewComplianceNewsDetail(this.state.id)
        } else {
            response = await ccmsService.getComplianceNewsDetail(this.state.id)
        }
        if (response.code === 200) {
            checkIsLoadWatermark(response.data.isShowWatermark)
            response.data.publishDate = formatDate('YYYY-mm-dd', new Date(response.data.publishDate))
            let isPdf = response.data.contentType === COMPLIANCE_NEWS_CONTENT_TYPE.ENUM.PDF.value
            let pdfContentUrl= ''
            if (isPdf) {
                pdfContentUrl = await azureService.getFileUrlFromAzure(JSON.parse(response.data.contentFile)[0].fullName)
            }
            this.setState({
                compliancePolicyDetail: response.data,
                pdfContentUrl,
                isPdf,
            }, () => {
                const imgs = document.getElementsByTagName("img");
                for (const img of imgs) {
                    img.style.pointerEvents = 'none'
                    img.oncontextmenu = (e) => e.preventDefault()
                }
                const videos = document.getElementsByTagName("video");
                for (const video of videos) {
                    video.setAttribute('webkit-playsinline', 'true')
                    video.setAttribute('playsinline', 'true')
                }
                if(!isPdf) {
                    const alinks = document.getElementsByTagName("a");
                    setTimeout(() => {
                        for (const alink of alinks) {
                            const link = alink.href
                            const currentLink = window.location.href;
                            if(link && link !== 'javascript:void(0)' && link.indexOf('ccms-test.mcdonalds.com.cn') !==-1 && link.indexOf('ccms.mcdonalds.com.cn') && currentLink.indexOf('code=') === -1) {
                                alink.setAttribute('href', "javascript:void(0)");
                                alink.setAttribute('onclick', "window.location.href ='" + link+'?v='+Math.random()+"'; setTimeout(() => { window.location.reload(); }, 100);");
                            }          
                        } 
                    }, 200); 
                }
            })
        } else {
            if (response.code === 403) {
                this.props.history.replace({ 
                    pathname: '/error',
                })
            } else {
                this.props.history.replace({ 
                    pathname: `/compliance-policy`,
                    state: { isShowDetailFail: true }
                })
            }
            
        }
    }

    closeWindow = () => {
        if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow');
        } else {
            const currentLink = window.location.href;
            if(currentLink.indexOf('code=') === -1) {
                setTimeout(() => { window.location.reload(); }, 100);
            }
        }
    }

    render() {
        document.title = intl.get('COMPLIANCE_POLICY_DETAIL_TITLE')

        return (
            <DetailContainer>
                <DetailContentContainer>
                    <DetailHeaderContainer>
                        <div className="title">
                            {this.state.compliancePolicyDetail.title}
                        </div>
                        <div className="more-info">
                            {this.state.compliancePolicyDetail.publishDate}
                            <Space />
                            {this.state.compliancePolicyDetail.author}
                        </div>
                    </DetailHeaderContainer>
                    {
                        this.state.isPdf ? 
                        <DetailPDFContentContainer>
                            <PDFView pdfUrl={this.state.pdfContentUrl} id={this.state.id}/>
                        </DetailPDFContentContainer>
                        : 
                        <DetailImageContentContainer dangerouslySetInnerHTML={{__html:this.state.compliancePolicyDetail.content}}/>
                    }
                </DetailContentContainer>
            </DetailContainer>
        )
    }
}

export default withRouter(Detail)

const DetailContainer = styled.div`
    display: flex;
`

const DetailContentContainer = styled.div`
    background-color: white;
    width: 100%;
    margin: -10px 16px 16px;
    border-radius: 8px;
    overflow-x: hidden;
`

const DetailHeaderContainer = styled.div`
    padding: 20px 20px 8px;
    .title {
        font-size: 18px;
        font-weight: bold;
    }
    .more-info {
        margin-top: 4px;
        font-size: 14px;
        opacity: 0.6;
    }
`

const DetailPDFContentContainer = styled.div`
    padding: 0px 16px;
`

const DetailImageContentContainer = styled.div`
    padding: 0 20px 20px;
    word-break: break-all;
    ul {
        list-style-type: disc;
        margin-left: -6px;
        ul {
            list-style-type: circle;
            ul {
                list-style-type: square;
            }
        }
    }
    li {
        list-style-position: outside;
    }
    ol {
        margin-left: -6px;
        list-style: decimal;
    }
    video {
        max-width: 100%;
    }
    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }
    img {
        max-width: 100% !important;
        height: auto !important;
    }
`

const Space = styled.span`
    margin: 0 7px;
`