import { v4 as uuidv4 } from 'uuid'
import { FILE_CHECK_RESULT_TYPE_ERROR, FILE_CHECK_RESULT_SIZE_ERROR, FILE_CHECK_RESULT_VALID } from './const'
import {
    SUPPORTED_FILES_TYPE_FOR_DOC,
    SUPPORTED_FILES_TYPE_FOR_IMAGE,
    SUPPORTED_FILES_TYPE_FOR_AUDIO,
    SUPPORTED_FILES_TYPE_FOR_VIDEO,
    SUPPORTED_FILES_TYPE_FOR_OTHER,
    INFORMER_SECURITY_TYPE_ENUM
} from './const'
import store from '../store'
import watermark from './watermark'

export const checkFile = async (file) => {
    if (file === null) return FILE_CHECK_RESULT_TYPE_ERROR

    let fileName = file.name
    if (fileName.lastIndexOf('.') <= 0) return FILE_CHECK_RESULT_TYPE_ERROR

    let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
    let allSupportedFileTypes = SUPPORTED_FILES_TYPE_FOR_DOC
        .concat(SUPPORTED_FILES_TYPE_FOR_IMAGE)
        .concat(SUPPORTED_FILES_TYPE_FOR_AUDIO)
        .concat(SUPPORTED_FILES_TYPE_FOR_VIDEO)
        .concat(SUPPORTED_FILES_TYPE_FOR_OTHER)

    if (allSupportedFileTypes.indexOf(fileExtension) < 0) return FILE_CHECK_RESULT_TYPE_ERROR

    if (file.size > 52428800) return FILE_CHECK_RESULT_SIZE_ERROR

    const buffers = await readBuffer(file, 0, 2);
    const uint8Array = new Uint8Array(buffers);
    let header = '';
    for (var i = 0; i < uint8Array.length; i++) {
      header += uint8Array[i].toString(10);
    }
    let oldVersionDoc = ['doc', 'xls', 'ppt', 'wps'];
    let newVersionDoc = [ 'docx', 'pptx', 'xlsx', 'zip'];
    if (oldVersionDoc.indexOf(fileExtension) >= 0) {
      if (header !== '208207') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    } else if (newVersionDoc.indexOf(fileExtension) >= 0) {
      if (header !== '8075') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    } else if(fileExtension === 'gif') {
      if (header !== '7173') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    } else if(fileExtension === 'jpg') {
      if (header !== '255216') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    } else if(fileExtension === 'png') {
      if (header !== '13780') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    } else if(fileExtension === 'pdf') {
      if (header !== '3780') {
        return FILE_CHECK_RESULT_TYPE_ERROR;
      }
    }

    return FILE_CHECK_RESULT_VALID
}

export const readBuffer = async (file, start = 0, end = 2) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, end));
  });
}

export const getFormatFile = file => {
    let fileFullName = file.name
    let index = fileFullName.lastIndexOf('.')
    let fileName = fileFullName.substring(0, index)
    let generatedUuid = uuidv4()
    let fileExtension = fileFullName.substring(fileFullName.lastIndexOf('.') + 1)

    file.generatedFileName = `${fileName}_${generatedUuid}.${fileExtension}`

    if (SUPPORTED_FILES_TYPE_FOR_DOC.indexOf(fileExtension) >= 0) {
        file.formatType = 'doc'
    } else if (SUPPORTED_FILES_TYPE_FOR_IMAGE.indexOf(fileExtension) >= 0) {
        file.formatType = 'image'
    } else if (SUPPORTED_FILES_TYPE_FOR_AUDIO.indexOf(fileExtension) >= 0) {
        file.formatType = 'audio'
    } else if (SUPPORTED_FILES_TYPE_FOR_VIDEO.indexOf(fileExtension) >= 0) {
        file.formatType = 'video'
    } else if (SUPPORTED_FILES_TYPE_FOR_OTHER.indexOf(fileExtension) >= 0) {
        file.formatType = 'other'
    } else {
        file.formatType = 'other'
    }

    return file
}

export const getFormatInformRequset = (values, fileList = [], kaptchaId) => {
    let fileNames = []
    if (fileList.length > 0) {
        fileList.forEach(item => {
            if (item.status === 'done') {
                let data = {
                    name: item.name,
                    fullName: item.generatedFileName,
                    type: item.formatType
                }
                fileNames.push(data)
            }
        })
    }
    let fileNamesJsonString = JSON.stringify(fileNames)

    return {
        "informerSecurityType": values.informerSecurityType,
        "informerName": values.informerSecurityType === INFORMER_SECURITY_TYPE_ENUM.REAL_NAME.value ? values.informerNameRequired.trim() : (values.informerName ? values.informerName.trim() : ''),
        "informerType": values.informerType,
        "informerTypeComment": values.informerTypeComment ? values.informerTypeComment.trim() : '',
        "informerContactInfo": values.informerContactInfo ? values.informerContactInfo.trim() : '',
        "informerOtherContactInfo": values.informerOtherContactInfo ? values.informerOtherContactInfo.trim() : '',
        "informRegion": values.informRegion,
        "informType": values.informType,
        "informTypeComment": values.informTypeComment ? values.informTypeComment.trim() : '',
        "informContent": values.informContent ? values.informContent.trim() : '',
        "informFiles": fileNamesJsonString,
        "informedType": values.informedType,
        "informedTypeComment": values.informedTypeComment ? values.informedTypeComment.trim() : '',
        "informedName": values.informedName ? values.informedName.trim() : '',
        "informedJobPosition": values.informedJobPosition ? values.informedJobPosition.trim() : '',
        "informedLineOfBusiness": values.informedLineOfBusiness ? values.informedLineOfBusiness.trim() : '',
        "informedContactInfo": values.informedContactInfo ? values.informedContactInfo.trim() : '',
        "kaptchaCode": values.kaptchaCode.trim(),
        "kaptchaId": kaptchaId,
        "language": store.getState().LanguageData.language
    }
}

export const getFormatExtraInformRequset = (values, fileList = [], kaptchaId) => {
    let fileNames = []
    if (fileList.length > 0) {
        fileList.forEach(item => {
            if (item.status === 'done') {
                let data = {
                    name: item.name,
                    fullName: item.generatedFileName,
                    type: item.formatType
                }
                fileNames.push(data)
            }
        })
    }
    let fileNamesJsonString = JSON.stringify(fileNames)

    return {
        "informNumber": values.informNumberForExtra ? values.informNumberForExtra.trim() : '',
        "extraInformContent": values.informContentForExtra ? values.informContentForExtra.trim() : '',
        "extraInformFiles": fileNamesJsonString,
        "kaptchaCode": values.kaptchaCodeForExtra.trim(),
        "kaptchaId": kaptchaId
    }
}

/**
 * 日期格式化
 * @param {*} fmt 字符串，例如 'YYYY-mm-dd'
 * @param {*} date Date类型，即需要格式化的日期
 * @returns 
 */
export const formatDate = (fmt, date) => {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

export const checkDeviceIsMobile = () => {
  const ua = navigator.userAgent

  const isWindowsPhone = /(?:Windows Phone)/.test(ua)
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
  const isAndroid = /(?:Android)/.test(ua)
  const isFireFox = /(?:Firefox)/.test(ua)
  const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet
  const isPc = !isPhone && !isAndroid && !isSymbian

  return !isPc;
}

export const checkIsRenderHeaderAndFooter = () => {
  return window.location.hash.indexOf('#/h5-game') === -1
}

export const checkIsLoadWatermark = (isShowWatermark) => {
  if (isShowWatermark) {
    watermark.load(!checkDeviceIsMobile() ? { x: 100, xSpace: 300 } : undefined)
  } else {
    watermark.remove()
  }
}
