import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { message, Typography, Radio, Form, Button, Input } from 'antd'

import ccmsService from '../../services/ccmsService'
import wechatShareService from '../../services/wechatShareService'

import Config from '../../config/initEnv'
import { checkDeviceIsMobile } from '../../common/utils'

import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'
const { Title } = Typography

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: {
        type: 1,
        companyName: '',
        name: '',
        organizationCode: '',
        queryKeyword: '',
      },
      list: [],
      detail: {},
      loadPage: false,
    }
  }
  formRef = React.createRef()

  async componentDidMount() {
    window.addEventListener('popstate', this.closeWindow)
    wechatShareService.hideOptionMenu()
    store.dispatch(updateIsVisibleLanguageSwitch(false))
    // 获取权限
    let response = await ccmsService.getRestrictedPartnerPermission()
    if (response.code === 200) {
      let search = this.state.search
      if (sessionStorage.getItem('RestrictivePartnerSearch')) {
        search = JSON.parse(sessionStorage.getItem('RestrictivePartnerSearch'))
      }
      this.setState({
        loadPage: true,
        search,
      }, () => {
        this.formRef.current.setFieldsValue({
          ...search,
        })
      })
    } else if (response.code === 403) {
      this.props.history.replace({
        pathname: '/error',
      })
      return
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.closeWindow)
    store.dispatch(updateIsVisibleLanguageSwitch(true))
  }

  closeWindow = () => {
    if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
      window.WeixinJSBridge.call('closeWindow');
    }
  }

  downloadFile = (url) => {
    if (checkDeviceIsMobile()) {
      const xhr = new XMLHttpRequest()
      xhr.open('HEAD', url, true)
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            const size = xhr.getResponseHeader('Content-Length')
            window.wx.previewFile({
              url,
              size
            })
          }
        }
      }
      xhr.send()
    } else {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  onFinish = async (values) => {
    let request = {
      ...values
    };
    if (values.type === 1) {
      request.name = values.companyName
      delete request.companyName
    }
    let response = await ccmsService.getRestrictedPartner(request)
    if (response.code === 200) {
      const { data } = response
      this.setState({
        search: {
          ...this.state.search,
          queryKeyword: data.queryKeyword
        },
        list: data.list,
        detail: {
          isCanCooperate: data.isCanCooperate,
          type: data.type,
          name: data.name,
          restrictionType: data.restrictionType,
          restrictionStartTime: data.restrictionStartTime,
          restrictionEndTime: data.restrictionEndTime,
        }
      })
      sessionStorage.setItem('RestrictivePartnerSearch', JSON.stringify(this.state.search));
      sessionStorage.setItem('RestrictivePartnerList', JSON.stringify(this.state.list));
      sessionStorage.setItem('RestrictivePartnerDetail', JSON.stringify(this.state.detail));
      if (data.isNeedFurtherQuery) {
        this.props.history.push({
          pathname: `/restrictive-partner-list`,
        })
      } else {
        this.props.history.push({
          pathname: `/restrictive-partner-detail`,
        })
      }
    } else {
      message.error(response.message)
    }
  }
  render() {
    document.title = intl.get('RESTRICTION_PARTNER_TITLE')

    return (
      this.state.loadPage &&
      <>
        <PageContainer style={{ marginTop: '-9px' }}>
          <TitleDescription>
            麦当劳仅与声誉良好、适当的第三方开展业务往来。在此原则下我们建立限制合作方管理机制（具体可点击下方“限制合作方的政策详情”链接了解），您可以通过下方输入拟合作方信息查询是否属于限制合作方。
            <WarningDescription>本查询内容仅供麦当劳内部使用。</WarningDescription>
          </TitleDescription>
        </PageContainer>
        <PageContainer>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{
            type: 1
          }}>
            <LabelContainer>
              <Title level={5}>拟合作方</Title>
            </LabelContainer>
            <Form.Item
              name="type">
              <Radio.Group onChange={(e) => {
                this.setState({
                  search: {
                    ... this.state.search,
                    type: e.target.value
                  }
                });

              }}>
                <Radio value={1}>公司</Radio>
                <Radio value={2}>个人</Radio>
              </Radio.Group>
            </Form.Item>
            {
              this.state.search.type === 1 ?
                <>
                  <LabelContainer>
                    <Title level={5}>公司名称</Title>
                    <LabelRequired>必填项</LabelRequired>
                  </LabelContainer>
                  <Form.Item
                    name="companyName"
                    rules={[
                      { required: true, message: "请输入公司全称查询" },
                      { whitespace: true, message: "请输入公司全称查询" }
                    ]}>
                    <Input maxLength={1024} allowClear={true} placeholder="请输入公司全称查询" onChange={
                      (e) => {
                        this.setState({
                          search: {
                            ...this.state.search,
                            companyName: e.target.value.trim(),
                          }
                        })
                      }
                    } />
                  </Form.Item>
                  <LabelContainer>
                    <Title level={5}>组织机构代码证</Title>
                    <LabelRequired>非必填项</LabelRequired>
                  </LabelContainer>
                  <Form.Item
                    name="organizationCode"
                  >
                    <Input maxLength={1024} allowClear={true} placeholder="请输入完整机构代码证查询" onChange={
                      (e) => {
                        this.setState({
                          search: {
                            ...this.state.search,
                            organizationCode: e.target.value.trim(),
                          }
                        })
                      }
                    } />
                  </Form.Item>
                </>
                :
                <>
                  <LabelContainer>
                    <Title level={5}>个人姓名查询</Title>
                    <LabelRequired>必填项</LabelRequired>
                  </LabelContainer>
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "请输入个人全名查询" },
                      { whitespace: true, message: "请输入个人全名查询" }
                    ]}>
                    <Input maxLength={1024} allowClear={true} placeholder="请输入个人全名查询" onChange={
                      (e) => {
                        this.setState({
                          search: {
                            ...this.state.search,
                            name: e.target.value.trim(),
                          }
                        })
                      }
                    } />
                  </Form.Item>
                </>
            }
            <SubmitButton>
              <Button type="primary" block={true} htmlType="submit">查询</Button>
            </SubmitButton>
            <BlueLinkContainer onClick={() => this.downloadFile(Config.restrictedPartnerPolicyDetail)}>限制合作方的政策详情</BlueLinkContainer>

          </Form>
        </PageContainer>
      </>
    )
  }
}

export default withRouter(Index)

const PageContainer = styled.div`
  margin: 16px;
  padding: 16px;
  background: white;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px rgba(223,230,234,1);
  form {
    .ant-radio-group {
      display: flex;
      gap: 10px;
    }
    .ant-radio-wrapper {
      padding: 0;
      padding-left: 8px;
      width: 90px;
      height: 36px;
      border: 1px solid rgba(199,201,207,1);
      border-radius: 25px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #9A9A9A;
      .ant-radio-inner {
        width: 22px;
        height: 22px;
        border: 1px solid rgba(199,201,207,1);
        &::after {
          display: none;
        }
      }
    }
    .ant-radio-wrapper-checked {
      background: #1890ff;
      color: white;
      border: 1px solid #1890ff;
      font-weight: bold;
      .ant-radio-inner {
        background: #1890ff;
        border: 5px solid white;
      }
    }
    .ant-input-affix-wrapper {
      padding: 1px 11px;
      border-radius: 4px;
    }
    .ant-input {
      background: none !important;
    }
    .ant-form-item {
      margin-bottom: 36px;
    }
  }
`
const TitleDescription = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`

const WarningDescription = styled.div`
  color: #ff4d4f;
`

const LabelContainer = styled.div`
  display: flex;
  align-item: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  .ant-typography {
    margin-bottom: 0;
  }
`
const LabelRequired = styled.div`
  font-size: 12px;
  color: #7B7B7B;
`
const SubmitButton = styled.div`
  margin: 60px 0 24px;
  button {
    border-radius: 4px;
  }
`

const BlueLinkContainer = styled.div`
  color: #1890ff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
`
