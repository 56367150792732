import React, { Component } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Modal } from 'antd'

import store from './store';
import { addLanguage, updateLanguage } from './actions/languageActions';

import { checkDeviceIsMobile, checkIsRenderHeaderAndFooter } from './common/utils'
import watermark from './common/watermark'

import MobileHome from './views/MobileHome'
import MobileNotice from './views/MobileNotice'
import MobileForm from './views/MobileForm'
import MobileSuccess from './views/MobileSuccess'
import Error from './views/Error'
import CompliancePolicy from './views/CompliancePolicy/Index'
import CompliancePolicyDetail from './views/CompliancePolicy/Detail'
import ComplianceTips from './views/ComplianceTips/Index'
import ComplianceTipsDetail from './views/ComplianceTips/Detail'
import RestrictivePartnerDetail from './views/RestrictivePartner/Detail'
import RestrictivePartnerList from './views/RestrictivePartner/List'
import RestrictivePartnerSearch from './views/RestrictivePartner/Search'
import IntegrityCultureWeekList from './views/IntegrityCultureWeek/Index'
import IntegrityCultureWeekSummary from './views/IntegrityCultureWeek/Summary'
import IntegrityCultureWeekDetail from './views/IntegrityCultureWeek/Detail'
import H5Game from './views/H5Game/Index'

import PcHome from './views/PcHome'
import PcNotice from './views/PcNotice'
import PcForm from './views/PcForm'
import PcSuccess from './views/PcSuccess'

import WechatWorkOAuth from './components/WechatWorkOAuth'

import mlogo from './assets/images/logo.png'

const locales = {
    'zh-CN': require('./locales/zh-CN.json'),
    'zh-HK': require('./locales/zh-HK.json'),
    'en-US': require('./locales/en-US.json')
}

export const AppContainer = styled.div`
`

export const AppHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #00182e;
`

export const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 40px;
    width: 100%;
    height: 60px;
    background-color: #00182e;

    &.pc-header-container {
        max-width: 1024px;
        padding: 0 20px;
    }
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
`

const LogoImage = styled.img`
    width: auto;
    height: 16px;
`

export const LanguageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    color: white;
    font-size: 12px;

    .language-type {
        font-size: 12px;

        &.active {
            font-size: 14px;
        }

        :hover {
            cursor: pointer;
        }
    }
`

export const ChooseLanguageModalContainer = styled.div`
    padding: 10px;
`

export const LanguageModalLanguageItem = styled.div`
    padding: 10px;
    text-align: center;
    font-size: 16px;

    &.active {
        font-size: 18px;
        font-weight: bold;
    }
`

export const MobileContainer = styled.div`
`

export const PcContainer = styled.div`
    width: 1024px;
    min-height: calc(100vh - ${checkIsRenderHeaderAndFooter() ? 140 : 0}px);
    margin: auto;
`
export const AppFooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: #00182e;
    font-size: 14px;
    color: #fff;
`

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false,
            lang: 'zh-CN',
            isLoadLocalsDone: false,
            isChooseLanguageModalVisible: false,
            isVisibleLanguageSwitch: store.getState().commonData.isVisibleLanguageSwitch,
            pcContainerWidth: store.getState().commonData.pcContainerWidth,
        }
        store.subscribe(() => {
            this.setState({
                isVisibleLanguageSwitch: store.getState().commonData.isVisibleLanguageSwitch,
                pcContainerWidth: store.getState().commonData.pcContainerWidth,
            })
        })
    }

    componentDidMount() {
        this.checkLanguage()
        this.checkDevice()
        window.addEventListener('resize', this.handleResize.bind(this))
    }

    checkLanguage = () => {
        let defaultLanguage = navigator.language.toLowerCase()
        if (defaultLanguage.substr(0, 2) === 'en') {
            this.setState({ lang: 'en-US' }, this.loadLocales('en-US'))
            store.dispatch(addLanguage('en-US'));
        } else if (defaultLanguage.substr(0, 2) === 'zh') {
            if (defaultLanguage.indexOf('cn') > 0) {
                this.setState({ lang: 'zh-CN' }, this.loadLocales('zh-CN'))
                store.dispatch(addLanguage('zh-CN'));
            } else {
                this.setState({ lang: 'zh-HK' }, this.loadLocales('zh-HK'))
                store.dispatch(addLanguage('zh-HK'));
            }
        }
    }

    loadLocales = (language = 'zh-CN') => {
        intl.init({
            currentLocale: language,
            locales
        }).then(() => {
            this.setState({
                lang: language,
                isLoadLocalsDone: true,
                isChooseLanguageModalVisible: false
            })

            store.dispatch(updateLanguage(language))
            if (this.state.isVisibleLanguageSwitch) {
                if (process.env.REACT_APP_SECRET_ENV !== 'production') {
                    document.title = `UAT-${intl.get('APP_TITLE')}`
                } else {
                    document.title = intl.get('APP_TITLE')
                }
            }
        })
    }

    checkDevice = () => {
        this.setState({ isMobile: checkDeviceIsMobile() })
    }

    handleResize = e => {
        if (e.target.innerWidth <= 768) {
            this.setState({ isMobile: true })
        } else {
            this.setState({ isMobile: false })
        }
    }

    handleChangeLanguage = language => {
        this.loadLocales(language)
    }

    onClickLanguageContainer = () => {
        this.setState({ isChooseLanguageModalVisible: true })
    }

    closeChooseLanguageModal = () => {
        this.setState({ isChooseLanguageModalVisible: false })
    }

    render() {
        const urlHash = window.location.hash
        if (urlHash.indexOf('#/compliance-tips/') === -1 && urlHash.indexOf('#/compliance-policy/') === -1) {
            watermark.remove()
        }
        const isRenderHeaderAndFooter = checkIsRenderHeaderAndFooter();
        return (
            this.state.isLoadLocalsDone && (
                <AppContainer className="App">
                    { 
                    isRenderHeaderAndFooter &&
                    <AppHeaderContainer>
                        <ContentContainer className={!this.state.isMobile ? "pc-header-container" : null}>
                            <LogoContainer>
                                <LogoImage src={mlogo} />
                            </LogoContainer>
                            {
                                    this.state.isVisibleLanguageSwitch &&
                                    <LanguageContainer onClick={this.onClickLanguageContainer}>
                                        <span className={`language-type ${this.state.lang === 'zh-CN' ? "active" : null}`}>简</span>
                                        <span>&nbsp;/&nbsp;</span>
                                        <span className={`language-type ${this.state.lang === 'zh-HK' ? "active" : null}`}>繁</span>
                                        <span>&nbsp;/&nbsp;</span>
                                        <span className={`language-type ${this.state.lang === 'en-US' ? "active" : null}`}>EN</span>
                                    </LanguageContainer>
                            }
                        </ContentContainer>

                        <Modal
                            maskClosable={false}
                            centered={true}
                            visible={this.state.isChooseLanguageModalVisible}
                            footer={null}
                            onCancel={this.closeChooseLanguageModal}
                            width={300}>
                            <ChooseLanguageModalContainer>
                                <LanguageModalLanguageItem
                                    onClick={this.handleChangeLanguage.bind(this, 'zh-CN')}
                                    className={["cursor-pointer", this.state.lang === 'zh-CN' ? "active" : null]}>简体中文</LanguageModalLanguageItem>
                                <LanguageModalLanguageItem
                                    onClick={this.handleChangeLanguage.bind(this, 'zh-HK')}
                                    className={["cursor-pointer", this.state.lang === 'zh-HK' ? "active" : null]}>繁體中文</LanguageModalLanguageItem>
                                <LanguageModalLanguageItem
                                    onClick={this.handleChangeLanguage.bind(this, 'en-US')}
                                    className={["cursor-pointer", this.state.lang === 'en-US' ? "active" : null]}>English</LanguageModalLanguageItem>
                            </ChooseLanguageModalContainer>
                        </Modal>
                    </AppHeaderContainer>
                    }
                    {
                        this.state.isMobile ?
                            <MobileContainer className="app-mobile-container">
                                <Router>
                                    <Route path="/" exact component={MobileHome}></Route>
                                    <Route path="/home" exact component={MobileHome}></Route>
                                    <Route path="/notice" exact component={MobileNotice}></Route>
                                    <Route path="/form" exact component={MobileForm}></Route>
                                    <Route path="/success/:code" exact component={MobileSuccess}></Route>
                                    <Route path="/error" exact component={Error}></Route>
                                    <Route path="/auth/:page" exact component={WechatWorkOAuth}></Route>
                                    <Route path="/compliance-policy" exact component={CompliancePolicy}></Route>
                                    <Route path="/compliance-policy/:id" exact component={CompliancePolicyDetail}></Route>
                                    <Route path="/compliance-tips" exact component={ComplianceTips}></Route>
                                    <Route path="/compliance-tips/:id" exact component={ComplianceTipsDetail}></Route>
                                    <Route path="/restrictive-partner-detail" exact component={RestrictivePartnerDetail}></Route>
                                    <Route path="/restrictive-partner-list" exact component={RestrictivePartnerList}></Route>
                                    <Route path="/restrictive-partner-search" exact component={RestrictivePartnerSearch}></Route>
                                    <Route path="/integrity-culture-week" exact component={IntegrityCultureWeekList}></Route>
                                    <Route path="/integrity-culture-week/:id" exact component={IntegrityCultureWeekSummary}></Route>
                                    <Route path="/integrity-culture-week/detail/:id" exact component={IntegrityCultureWeekDetail}></Route>
                                    <Route path="/h5-game" exact component={H5Game}></Route>
                                </Router>
                            </MobileContainer> :
                            <PcContainer className="app-pc-container" style={this.state.isVisibleLanguageSwitch ? {} : {width: `${this.state.pcContainerWidth}px`}}>
                                <Router>
                                    <Route path="/" exact component={PcHome}></Route>
                                    <Route path="/home" exact component={PcHome}></Route>
                                    <Route path="/notice" exact component={PcNotice}></Route>
                                    <Route path="/form" exact component={PcForm}></Route>
                                    <Route path="/success/:code" exact component={PcSuccess}></Route>
                                    <Route path="/error" exact component={Error}></Route>
                                    <Route path="/auth/:page" exact component={WechatWorkOAuth}></Route>
                                    <Route path="/compliance-policy" exact component={CompliancePolicy}></Route>
                                    <Route path="/compliance-policy/:id" exact component={CompliancePolicyDetail}></Route>
                                    <Route path="/compliance-tips" exact component={ComplianceTips}></Route>
                                    <Route path="/compliance-tips/:id" exact component={ComplianceTipsDetail}></Route>
                                    <Route path="/restrictive-partner-detail" exact component={RestrictivePartnerDetail}></Route>
                                    <Route path="/restrictive-partner-list" exact component={RestrictivePartnerList}></Route>
                                    <Route path="/restrictive-partner-search" exact component={RestrictivePartnerSearch}></Route>
                                    <Route path="/integrity-culture-week" exact component={IntegrityCultureWeekList}></Route>
                                    <Route path="/integrity-culture-week/:id" exact component={IntegrityCultureWeekSummary}></Route>
                                    <Route path="/integrity-culture-week/detail/:id" exact component={IntegrityCultureWeekDetail}></Route>
                                    <Route path="/h5-game" exact component={H5Game}></Route>
                                </Router>
                            </PcContainer>
                    }

                    {
                        !this.state.isMobile && isRenderHeaderAndFooter && (
                            <AppFooterContainer>{intl.get('COPY_RIGHT_LABEL')}</AppFooterContainer>
                        )
                    }
                </AppContainer>
            )
        )
    }
}

export default App
