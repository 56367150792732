const defaultSettings = {
  firstLineMaxLength: 30, // 首行文字最大字符数，超过截断并显示省略号,
  secondLineText: '内部信息禁止外泄', // 第二行固定文字,
  fontSize: 15, // 水印字体大小
  fontFamily: 'NotoSansSC-Regular', // 水印字体
  fontWeight: '500', // 水印字体粗细
  color: '#000', //水印字体颜色
  alpha: '0.15', // 水印透明度，要求设置在大于等于0.005
  lineHeight: 20, // 行高
  angle: 20, // 水印倾斜度数
  xSpace: 54, // 水印x轴间隔
  ySpace: 60, // 水印y轴间隔
  x: 20, // 水印起始位置x轴坐标
  y: 62 // 水印起始位置Y轴坐标
}

function truncateText(text, settings) {
  const textLength = text.length
  if (textLength > settings.firstLineMaxLength) {
      return text.slice(0, settings.firstLineMaxLength) + '...'
  }
  return text
}

function getTextWidth(text, font) {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const ctx = canvas.getContext('2d')
  ctx.font = font
  return ctx.measureText(text).width
}

function loadWaterMark(settings) {
  let shadowRoot
  const watermarkDiv = document.createElement('div')
  watermarkDiv.className = 'watermark-class'
  watermarkDiv.setAttribute('style', 'pointer-events: none !important; display: block !important');
  if(typeof watermarkDiv.attachShadow === 'function'){
    shadowRoot = watermarkDiv.attachShadow({mode: 'open'})
  }else{
    shadowRoot = watermarkDiv
  }
  document.body.appendChild(watermarkDiv)
  
  settings = !settings ? defaultSettings : { ...defaultSettings, ...settings }

  const { wechatWorkUserId, nickname } = JSON.parse(sessionStorage.getItem('userInfo'))
  const firstLineText = truncateText(`${wechatWorkUserId} ${nickname}`, settings)
  const font = `${settings.fontWeight} ${settings.fontSize}px ${settings.fontFamily}`
  const firstLineWidth = getTextWidth(firstLineText, font)
  const secondLineWidth = getTextWidth(settings.secondLineText, font)
  const maxLineWidth = Math.max(firstLineWidth, secondLineWidth)

  const absDeg = Math.abs(Math.PI * settings.angle / 180)
  const wSinDeg = maxLineWidth * Math.sin(absDeg)
  const wCosDeg = maxLineWidth * Math.cos(absDeg)
  const hSinDeg = settings.lineHeight * 2 * Math.sin(absDeg)
  const hCosDeg = settings.lineHeight * 2 * Math.cos(absDeg)

  const textContainerWidth = hSinDeg + wCosDeg
  const textContainerHeight = wSinDeg + hCosDeg
  
  const xCount = Math.ceil((document.documentElement.clientWidth - settings.x) / (textContainerWidth + settings.xSpace))
  const yCount = Math.ceil((document.documentElement.clientHeight - settings.y) / (textContainerHeight + settings.ySpace))

  const deviation = 2
  for (let i = 0; i < yCount; i++) {
    for (let j = 0; j < xCount; j++) {
      const maskDiv = document.createElement('div')

      const firstLineDiv = document.createElement('div')
      firstLineDiv.appendChild(document.createTextNode(firstLineText))
      firstLineDiv.style.width = `${Math.ceil(maxLineWidth) + deviation}px`
      firstLineDiv.style.height = `${settings.lineHeight + deviation}px`
      firstLineDiv.style.lineHeight = `${settings.lineHeight}px`
      firstLineDiv.style.whiteSpace = 'nowrap'
      firstLineDiv.style.overflow = 'hidden'
      firstLineDiv.style.textOverflow = 'ellipsis'
      maskDiv.appendChild(firstLineDiv)
      maskDiv.appendChild(document.createTextNode(settings.secondLineText))

      maskDiv.style.transform = `rotate(-${settings.angle}deg)`
      maskDiv.style.visibility = ''
      maskDiv.style.position = 'fixed'
      maskDiv.style.left = `${settings.x + (textContainerWidth + settings.xSpace) * j}px`
      maskDiv.style.top = `${settings.y + (textContainerHeight + settings.ySpace) * i + (textContainerWidth / 2 * Math.sin(absDeg)) * (i+1)}px`
      maskDiv.style.overflow = 'hidden'
      maskDiv.style.zIndex = '9999999'
      maskDiv.style.opacity = settings.alpha
      maskDiv.style.fontSize = `${settings.fontSize}px`
      maskDiv.style.fontWeight = settings.fontWeight
      maskDiv.style.fontFamily = settings.fontFamily
      maskDiv.style.color = settings.color
      maskDiv.style.lineHeight = `${settings.lineHeight}px`
      maskDiv.style.textAlign = 'center'
      maskDiv.style.width = `${Math.ceil(maxLineWidth) + deviation}px`
      maskDiv.style.height = `${Math.ceil(settings.lineHeight * 2) + 3 * deviation}px`
      maskDiv.style.display = 'block'
      maskDiv.style['-ms-user-select'] = 'none'
      shadowRoot.appendChild(maskDiv)
    }
  }
}

function removeWatermark() {
  const watermarkDomList = Array.from(document.getElementsByClassName('watermark-class'))
  for (const watermarkDom of watermarkDomList) {
    watermarkDom.remove()
  }
}

const watermark = {
  remove: () => {
    removeWatermark()
  },
  load: (settings) => {
    removeWatermark()
    loadWaterMark(settings)
  }
}
 
export default watermark