import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import Config from '../config/initEnv'
import ccmsService from '../services/ccmsService'
import store from '../store'
import { updateIsVisibleLanguageSwitch } from '../actions/commonActions'
import { checkDeviceIsMobile } from '../common/utils'

const WechatWorkOAuthContainer = styled.main`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #f2f5f8;
`

class WechatWorkOAuth extends Component {

    componentDidMount() {
        store.dispatch(updateIsVisibleLanguageSwitch(false))
        const page = this.props.match.params.page
        if (page === 'compliance-policy') {
            document.title = intl.get('COMPLIANCE_POLICY_LIST_TITLE')
        } else if (page === 'compliance-tips') {
            document.title = intl.get('COMPLIANCE_TIPS_LIST_TITLE')
        } else if (page === 'restrictive-partner-search') {
            document.title = intl.get('RESTRICTION_PARTNER_TITLE')
        }


        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

            if (!code) {
                this.getWechatWorkOAuth2Code();
            } else {
                this.wechatWorkLogin(code)
            }


    }

    getWechatWorkOAuth2Code() {
        const redirectUri = encodeURIComponent(window.location.href)
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.wxWorkCorpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
        window.location.replace(url)
    }

    async wechatWorkLogin(code) {
        let res = await ccmsService.wechatWorkLogin(code)
        const goPage = checkDeviceIsMobile() ? this.props.history.push : this.props.history.replace
        if (res.code !== 200) {
            goPage({ pathname: `/error`, state: { code: res.code } })
        } else {
            sessionStorage.setItem('userInfo', JSON.stringify(res.data))
            const page = this.props.match.params.page
            // 合规政策列表页：https://ccms.mcdonalds.com.cn/#/auth/compliance-policy
            if (page === 'compliance-policy') {
                goPage({ pathname: '/compliance-policy' })

            // 合规提示列表页：https://ccms.mcdonalds.com.cn/#/auth/compliance-tips
            } else if (page === 'compliance-tips') {
                goPage({ pathname: '/compliance-tips' })

            // 限制合作方搜索：https://ccms.mcdonalds.com.cn/#/auth/restrictive-partner-search
            } else if (page === 'restrictive-partner-search') {
                goPage({ pathname: '/restrictive-partner-search' })

            // 诚信文化周列表页：https://ccms.mcdonalds.com.cn/#/auth/integrity-culture-week
            } else if (page === 'integrity-culture-week') {
                goPage({ pathname: '/integrity-culture-week' })

            } else {
                const arr = page.split('-');
                let urlSearch = this.props.location.search
                if (arr.length === 3 && arr[0] === 'compliance') {

                    // 合规政策详情页：https://ccms.mcdonalds.com.cn/#/auth/compliance-tips-{id}
                    if (arr[1] === 'policy') {
                        goPage({ pathname: `/compliance-policy/${arr[2]}${urlSearch}` })

                    // 合规提示详情页：https://ccms.mcdonalds.com.cn/#/auth/compliance-policy-{id}
                    } else if (arr[1] === 'tips') {
                        goPage({ pathname: `/compliance-tips/${arr[2]}${urlSearch}`})
                    }
                } else if (arr.length === 4 && `${arr[0]}-${arr[1]}-${arr[2]}` === 'integrity-culture-week') {
                    // 诚信文化周概要页：https://ccms.mcdonalds.com.cn/#/auth/integrity-culture-week-{id}
                    goPage({ pathname: `/integrity-culture-week/${arr[3]}${urlSearch}`})
                }
            }
        }
    }

    render() {
        return (<WechatWorkOAuthContainer />)
    }
}

export default withRouter(WechatWorkOAuth)
